/**=====================
     62. Responsive CSS Start
==========================**/
@media screen and (max-width: 1660px) {
  .blog-list{
    .blog-details{
      p{
        width: 100%;
        white-space: nowrap;
        overflow: hidden; 
        text-overflow: ellipsis;
      }
    }
  }
  .photos{
    ul{
      li{
        width: 22%;
      }
    }
  }
  .vertical-menu-main {
    .mega-menu {
      width: 1200px !important;
      max-width: 1200px !important;
      left: 0px !important;
    }
  }
  .user-status {
    table {
      thead {
        tr {
          th {
            &:nth-child(3) {
              min-width: 124px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 278px;
            }
          }
        }
      }
    }
  }
  .employee-status{
    table {
      tbody {
        tr {
          td{
            &:first-child{
              min-width:300px;
            }
          }
        }
      }
    }
  }
  .bar-chart {
    canvas {
      width: 100% !important;
    }
  }
  .main-header-right {
    .main-header-right {
      .left-menu-header {
        .orm-control-plaintext {
          .form-inline {
            width: auto !important;
          }
        }
      }
    }
  }
  .chat-box {
    .chat-history {     
      .call-icons {
        margin-bottom:15px;
        ul {
          li {            
            width: 45px;
            height: 45px;
            a {
              font-size: 18px;
            }
          }
        }
      }
      .total-time {
        h2 {
          font-size:40px;
          color: $dark-gray;
          margin-bottom:10px;
        }
      }
      .call-content {
        .btn {
          width: auto;
          margin: 0 auto;
        }
      }
    }
    .chat-right-aside {
      .chat {
        .chat-header {
          .chat-menu-icons {
            li {             
              &:nth-child(n+2){
                margin-left: 10px;
              }
              a {
                i {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
  .chat-left-aside {
    .people-list {
      height: 520px;
    }
  }
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-msg-box {
          height: 440px;
        }
      }
    }
    .people-list {
      ul {
        max-height: 450px;
        overflow: auto;
      }
    }
  }
  .call-chat-body {
    .chat-box {
      .people-list {
        ul {         
          max-height: 500px;
          overflow: auto;
        }
      }
      overflow: hidden;
      height: 625px;
    }   
  }
  .caller-img {   
    img {
      opacity: 0.7;
    }
  }
  .chat-box {
    .chat-history {
      .receiver-img {
        margin-top: 25px;
      }
      .call-content {
        min-height: 545px;        
      }
    }
  }
  // search //
  .search-page {
    .search-vid-block {
      > div {
        margin-top: 30px;
      }
    }
  }
  // user profile //
  .user-profile{
    .photos{
      ul{
        li{
          width:21%;
        }
      }
    }
  }


  // email wrap
  .email-wrap {
    .email-right-aside {
      .email-profile {
        .inbox {
          p{
            width:calc(100% - 300px);
            max-width:500px;
          }
        }
      }
    }
  }
  


}
@media screen and (max-width: 1550px) {
  .bg-gallery {
    height: 90vh;
  }
  .email-wrap .email-content .email-top .user-emailid:after{
    display: none;
  }
  .email-wrap .email-top .email-right{
    display: none;
  }
  .cal-date-widget {
    .datepicker{
      width:unset;
    }
  }
}
@media screen and (max-width: 1440px) {

  .target-sec .card-header ul.target-list li:nth-child(n + 2) p {
    padding: 0 5px;
  }
  .bg-gallery {
    height: 85vh;
    min-height: 700px;
  }
  .landing_gallery_section_img {
    &:before {
      content: "";
      height: 300px;
      background: linear-gradient(to top, #01397d 8%, transparent 92%);
    }
  }
  // header css  //
  .page-main-header {
    .main-header-right {
      .left-menu-header {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1660px) {
  .latest-articles{
    .card-body{
      .media{
        .m-r-30{
          margin-right: 10px;
        }
      }
    }
  }
  .whishlist-main{
    .prooduct-details-box .btn {     
      top: 0;
      bottom: unset;
      right: unset;
      left: 0;
  }
    .col-xl-2{
      width: 25%;
    }
  }
  .xl-none {
    display: none;
  }
  .xl-60 {
    max-width:60%;
    flex:0 0 60%;
  }
  .xl-30 {
    max-width:30%;
    flex:0 0 30%;
  }
  .xl-33 {
    max-width: 33.33333%;
    flex: 33.33333%;
  }
  .xl-35 {
    max-width:35%;
    flex:35%;
  }
  .xl-65 {
    max-width:65%;
    flex:65%;
  }
  .xl-60{
    max-width:60%;
    flex:60%;
  }
  .xl-70 {
    max-width:70%;
    flex:70%;
  }
  .xl-50 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .xl-100 {
    max-width: 100%;
    width: 100%;
    flex: 0 0 100%;
  }
  .xl-23 {
    max-width: 23%;
    flex: 0 0 23%;
  }
  .xl-25 {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .xl-40 {
    max-width: 40%;
    flex: 0 0 40%;
  }
  .xl-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
  .xl-cs-65 {
    max-width: 65%;
    flex: 0 0 65%;
  }
  .xl-cs-35 {
    max-width: 35%;
    flex: 0 0 35%;
    order: -1;
  } 
  .product-page-main {
    .owl-item {
      .item {
        border: 1px solid $light-color;
        border-radius: 5px;
      }
    }
    .proorder-xl-3{
      order:3;
    }
  }
  .owl-carousel {
    .owl-item {
      img {
        max-width: 420px;
        margin: 0 auto;
      }
    }
  }

  
  


}
@media screen and (max-width: 1580px) and (min-width: 768px) {
  //ecommerce page
  .product-filter {
    &.new-products {
      .owl-theme {
        .owl-item {
          .item {
            .product-box {
              .media {
                display: block;
                .product-img {
                  width: 100%;
                }
              }
              .product-details{
                padding:15px 0;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1580px) and (min-width: 1200px) {
  .docs-buttons,
  .docs-toggles {
    flex: 0 0 100%;
    max-width: 100%;
  }
  // landing-page
  .bg-sky-blue {
    .main-landing {
      height: 765px;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1214px) {
  .product-thumbnail,.product-main{
    flex: 0 0 auto;
    width: 100% !important; 
  }
  .product-thumbnail{
    margin-top: 15px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .knowledgebase-bg{
    height:400px;
  } 
  .custom-card .card-footer>div {
    padding: 10px 0;
  }
  .card-activity {
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: lighten($primary-color, 60%);
    }
    ::-webkit-scrollbar-thumb {
      background: $primary-color;
    }
    h5 {
      font-size: 18px !important;
    }
    .setting-list {
      display: none;
    }
  }
  .crm-activity span {
    display: none;
  }
  .vertical-menu-mt {
    .crm-activity {
      height: 298px;
    }
  }
  .pixelstrap {
    a {
      padding-left: 18px;
      &:hover,
      &:focus,
      &:active {
        padding-left: 18px;
      }
    }
  }
  .flot-chart-container-small {
    height: 227px;
  }
  .call-chat-sidebar {
    max-width: 260px;
  }
  .product-box {
    .product-details {
      padding: 15px;
    }
  }
  .flot-chart-container {
    height: 282px;
  }
  .custom-card {
    .card-profile {
      img {
        height: 80px;
      }
    }
    .profile-details {
      h4 {
        font-size: 18px;
      }
      h6 {
        margin-bottom: 24px;
      }
    }
    .card-footer {
      > div {
        h3 {
          font-size: 18px;
        }
      }
    }
  }
  .crm-activity {
    height: 291px;
    overflow-y: auto;
    .media {
      .media-body {
        .dates {
          display: flex;
        }
      }
    }
  }
  .calender-widget {
    .cal-date {
      width: 95px;
      height: 95px;
      h5 {
        line-height: 1.4;
        padding: 13px;
      }
    }
  }
  // chat
  .chat-body {
    padding: 10px;
  }
  .chat-box {
    .chat-history {
      .call-content {
        .btn{
          font-size: 15px;
        }
      }
      .total-time h2 {
        font-size: 30px;       
      }
      .receiver-img {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .call-icons {
        margin-top: 20px;
        margin-bottom: 20px;
        ul {
          li {
            width: 45px;
            height: 45px;           
          }
        }
      }
    }
    .user-image {
      width: 30px;
      height: 30px;
      margin-top: 3px;
      margin-right: 3px;
    }
    .about {
      margin-top:0;
    }
    .border-tab.nav-tabs {
      .nav-item {
        a {
          padding: 13px 4px !important;
          color: $semi-dark !important;
          letter-spacing: 0;                  
        }
      }
    }

    .chat-menu {
      .people-list {
        ul.list {
          max-height: 376px;
          overflow-y: auto;
        }
      }     
    }
  }

  .chat-left-aside {
    .people-list {
      height: 420px;
      ul {
        max-height: 376px;
        overflow-y: auto;
      }
    }
    .status-circle {
      top: 4px;
      left: 36px;
    }
  }
  //blog scss
  .blog-box {
    .blog-details-main {
      .blog-bottom-details {
        margin: 20px 10px;
      }
      .blog-social {
        li {
          padding: 0px 5px;
        }
      }
    }
  }
  .blog-box.blog-shadow {
    .blog-details {
      padding: 15px;
    }
  }
  .comment-box {
    .media {
      img{
        margin-right:25px;
      }
    }
    ul {
      ul{
        margin-left:50px;
      }
    }
  }
  

  .browser-widget {
    img {
      height: 50px;
    }
    .media-img {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 15px;
    }
  }

  // email-app
  .email-wrap {
    .email-left-aside {
      .email-app-sidebar {
        .media {
          display: block;
          .media-size-email {
            width: 100%;
            text-align: center;                      
            img{
              margin-bottom:10px;
            }
            .me-3 {
              margin-right: 0 !important;
            }
          }
          .media-body {
            text-align: center;
          }
        }
      }
    }
    .email-right-aside {
      .email-body {
        .attachment {
          ul {
            li {
              img {
                width: 73px;
                height: 73px;
              }
            }
          }
        }
        .email-top {
          line-height: 50px;
          h5 {
            padding: 13px 0;
          }
        }

        .inbox {
          // max-height: 668px;
          // overflow: auto;
          .media-body {
            text-align: center;
            h6 {
              font-size: 14px;
            }
          }
        }
        .email-compose {
          .cke_contents.cke_reset {
            max-height: 144px;
            width: 99%;
          }
          .compose-border {
            margin: 0;
          }
        }
      }    
    }
    .media-body{
      p{
        font-size:13px;
      }
    }
  }
  

  // alert page
  .alert-dismissible {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 181px;
    }
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 96% !important;
      }
    }
  }
  .blog-list {
    .blog-details {
      h6 {
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
      }
    }
  }

  .blog-box {
    .blog-date {
      span {
        font-weight: 500;
      }
    }
  }
}
@media screen and (max-width: 1470px) {
  .edit-profile {
    .profile-title {
      .media{
        display:block;
        .media-body {
          margin-left: unset;
          margin-top: 10px;         
        }
      }
    }
  }
  .user-profile{
    .photos{
      ul{
        li{
          width:20%;
        }
      }
    }
  }
  .page-main-header {
    .main-header-right {
      .left-menu-header{
        flex:0 0 35%;
      }
    }
  }


  // email wrap
  .email-wrap {
    .email-right-aside {
      .email-profile {
        .inbox {
          p{
            width:calc(100% - 300px);
            max-width:500px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1366px) {
  .email-wrap .email-content .email-top .user-emailid:after{
    display: none;
  }
  .lnb-calendars{
		display: block !important;
    	text-align: right;
	}


  .page-main-header{
    .main-header-right {
      .nav-right {
        .nav-menus{
          padding:20px 35px;
        }
      }
    }
  }



  // product page
  .span.ace_indent-guide{
    display: none;
  }
  .grid-options {
    ul {
      li {
        &:last-child {
          display: none;
        }
      }
    }
  }
  .product-wrapper-grid {
    .col-xl-3 {
      padding-left: 8px;
      padding-right: 8px;
    }
    &.list-view {
      .product-box {
        .product-img {
          width: 25%;
        }
      }
    }
  }
  .product-price {
    font-size: 14px;
  }
  .product-box {
    .product-img {
      .product-hover {
        ul {
          li {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
    .product-details {
      h4 {
        font-size: 18px;
      }
    }
  }

  // cart page
  .cart {
    .qty-box {
      width: 40%;
    }
  }

  // alert page
  .alert-dismissible {
    strong {
      & ~ p {
        display: inline;
      }
    }
  }


  // blog 
  .learning-box {
    .details-main{
      margin: 20px;
      h6{
        font-size:18px;
      }
    }
  }



  // timeline small page
  .timeline-small {
    .media {
      .media-body {
        p {
          width: 95%;
        }
      }
      .timeline-round {
        &.timeline-line-1 {
          &:after {
            bottom: -66px;
          }
        }
      }
    }
  }
  .map-js-height {
    height: 350px;
  }
  .browser-widget {
    .media {
      flex-wrap: wrap;
      .media-body {
        h3{
          font-size:20px;
        }
        p {
          margin-bottom: 2px;
        }
        h4 {
          font-size: 20px;
        }
      }
    }
  }
  .testimonial {
    i {
      font-size: 46px;
    }

    p {
      margin-top: 20px;
    }
  }
  .cal-date-widget .datepicker {
    margin: 0 auto;
    width: unset;
  }
  .company-card {
    padding: 20px;
  }
  .static-top-widget {
    .media-body {
      h3 {
        font-size: 30px;
      }

      .icon-bg {
        right: -21px;
      }
    }
  }
  .widget-joins {
    .media {
      padding: 20px;

      .media-body {
        h3 {
          font-size: 20px;
        }
      }
    }
  }  



  // book mark //
  .left-bookmark {
    ul {
      li {
        button{
          font-size:13px;
        }
      }
    }
  }


  



  // social app
  .photos {
    ul{
      li{
        width:21%;
      }
    }
  }


  // chat page
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-msg-box {
          height: 420px;
          .chat-user-img {
            margin-top: -30px;
          }
          .message {
            width: 100%;
            padding: 15px;
          }
        }
        .chat-header{
          .chat-menu-icons{
            li{
              a{
                i{
                  font-size:20px;
                }
              }
            }
          }
        }
      }
    }
    .chat-history {
      .call-content{
        min-height:512px;
      }
    }
  }
  .chat-right-aside {    
    .chat {
      .chat-message {
        .smiley-box {
          width: 45px;
        }
      }
    }
  }
  .chat-box {
    .chat-history {
      .receiver-img {
        img {
          height: 150px;
        }
      }
    }
  }
  .call-chat-body {
    .chat-box {
      overflow: hidden;
      height: 595px;
    }
  }

  // landing page css ends
  .calender-widget {
    .cal-desc {
      p {
        max-height: 88px;
        overflow: auto;
      }
    }
  }

  .equal-height-xl {
    height: 517px;
  }

  .authentication-main {
    .auth-innerright {
      background-size: cover;
    }
  }
  .grp-btns {
    display: inline-block;
  }
  .button-builder {
    .button-generator-bx {
      position: relative;
      width: 100%;
    }
  }
  .page-builder {
    .btn-group {
      display: inline-block;
      margin-bottom: 10px;
    }
    .btn-grid {
      margin-bottom: 15px;
      margin-right: 0px;
      border-right: none;
      display: inline-block;
    }
    .ge-canvas.ge-layout-desktop {
      margin-top: 50px;
    }
  }
  //ecommerce dashboard
  .ecommerce-widget {
    h6 {
      font-size: 14px;
    }
    .total-num {
      letter-spacing: 0px;
    }
  }
  .support-ticket {
    .pro-gress {
      .progress-showcase {
        margin-top: 15px;
      }
    }
  }
  .support-ticket-font {
    .total-num {
      font-size: 20px;
    }
  }

  //default dashboard
  .custom-card{
    padding:15px;
    .card-social{
      li{        
        a{
          width:30px;
          height:30px;
        }
      }
    }   
  }
  .map-chart {
    .chart-container {
      height: 300px;
    }
  }
  .speed-chart {
    .chart-container {
      height: 250px;
      margin-top: -40px;
      margin-bottom: 100px;
    }
  }
  .candidcahrt {
    height: 360px;
  }

  //chart widget
  .status-details {
    h3 {
      font-size: 18px;
    }
  }
  .chart-widget-top {
    .total-value {
      font-size: 20px;
    }
  }
  .status-widget{
    .card-body{
      padding:10px;
    }
  }

  //general widget 
  .browser-widget {
    .media {
      flex-wrap: wrap;
      .media-body {
        h3 {
          font-size:20px;
        }
        p {
          margin-bottom: 2px;
        }
        h4 {
          font-size: 20px;
        }
      }
    }
  }
  .testimonial {
    i {
      font-size: 46px;
    }
    p {
      margin-top: 20px;
    }
  }
  .cal-date-widget .datepicker {
    margin: 0 auto;
    width: unset;
  }
  .company-card {
    padding: 20px;
  }
  .static-top-widget {
    .media-body {
      h3 {
        font-size: 30px;
      }
      .icon-bg {
        right: -21px;
      }
    }
  }
  .widget-joins {
    .media {
      padding: 20px;
      .media-body {
        h3 {
          font-size: 20px;
        }
      }
    }
  }

  //chat
  .chat-body {
    padding: 10px;
  }
  .chat-box {
    .chat-history {
      .call-content {
        .btn {
          font-size: 15px;
        }
      }
      .total-time h2 {
        font-size: 30px;
      }
      .receiver-img {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .call-icons {
        margin-top: 20px;
        margin-bottom: 20px;
        ul {
          li {
            width: 45px;
            height: 45px;           
          }
        }
      }
    }
    .user-image {
      width: 45px;
      height: 45px;
    }
    .about {
      margin-top: 0;
    }
    .border-tab.nav-tabs {
      .nav-item {
        a {
          padding: 13px 4px !important;
          color: $semi-dark !important;
          letter-spacing: 0;
          font-size: 12px !important;         
        }
      }
    }
    .chat-menu {
      .people-list {
        ul.list {
          max-height: 376px;
          overflow-y: auto;
        }
      }     
    }
  }
  .chat-left-aside {
    .people-list {
      height: 490px;
      ul {
        max-height: 434px;
        overflow-y: auto;
      }
    }
  }
  .status-circle {
    top: 4px;
    left: 37px;
  }

  // mega-menu
  .mega-menu {
    .onhover-show-div {
      left: 164px;
    }
  }
  // image cropper page
  .img-cropper {
    .docs-toggles {
      margin-top: 8px;
    }
  }
  // touchspin page
  .bootstrap-touchspin {
    .dropdown-basic {
      .dropdown {
        .dropdown-content {
          left: -21px;
        }
      }
    }
  }
  // datatable advance page
  #advance-5_wrapper {
    .dataTables_paginate {
      margin-bottom: 8px;
    }
  }
  // jsgrid-table page
  #batchDelete {
    .jsgrid-grid-header {
      .jsgrid-table {
        .jsgrid-header-row {
          .jsgrid-header-cell {
            &:first-child {
              width: 67px !important;
            }
          }
        }
      }
    }
    .jsgrid-grid-body {
      .jsgrid-table {
        .jsgrid-row {
          .jsgrid-cell {
            &:first-child {
              width: 67px !important;
            }
          }
        }
      }
    }
  }
  // forget password page
  .reset-password-box {
    .theme-form {
      .form-group {
        .btn {
          padding: 6px 22px;
        }
      }
    }
  }
  .cd-timeline-img {
    width: 45px;
    height: 45px;
    i {
      font-size: 20px;
    }
  }
}
@media screen and (min-width:1200px) and (max-width: 1365px){
  .redial-social-widget{
    width:90px;
    height:90px;
    i{
      width:70px;
      height:70px;
    }
  }
  .social-widget-card{   
    .card-body{
      padding:20px;
    }       
    h5{
      margin-bottom:15px;
    }
  }
  .widget-joins{   
    .media{
      padding:15px;
      h5{
        font-size:14px;
      }
      .media-body{
        svg{
          width:25px;
          height:25px;
        }
        > span{
          font-size:13px;
        }
      }
      .me-3{
        margin-right:10px !important;
      }
    }
    
  }

  .testimonial {
    p{
      margin-top:unset;
    }
  }
  .general-widget {
    .custom-card {
      .card-header {
        img{
          margin-top:-45px;
        }
      }
    }
  }


}
@media screen and (min-width: 992px) and (max-width: 1199px){
  .project-box{
    padding:20px;
    .badge{
      right: 10px;
      top: 10px;
      font-size: 8px;
      padding: 5px;
    }
  }
}
@media screen and (max-width: 1280px) {
  .blog-list .blog-details{
    hr{
      display: none;
    }
    p{
      display: none;
    }
  }
  .page-main-header  {
    .main-header-right {
      .nav-right {
        > ul {
          > li{
            margin-right:10px;
          }
        }
      }
    }
  }
  .bg-gallery {
    height: 108vh;
  }
  .img-gallery-set1,
  .img-gallery-set5 {
    display: none;
  }
  .box-layout.page-wrapper.horizontal-wrapper {
    .page-main-header {
      padding-left: unset;
      max-width: unset;
      left: unset;
    }
    .page-body-wrapper {
      width: unset;
    }
  }
  // user profile
  .user-profile {
    .photos {
      ul {
        li{
          width:19%;
        }
      }
    }
    .social-tab {
      ul{
        li{
          &:nth-child(n+2){
            margin-left:10px;
          }
        }
      }
    }
  }

  // emaill application
  .email-wrap {
    .actions {
      li{
        &:nth-child(n+2){
          margin-left:10px;
        }
        .btn{         
          padding: 7px 20px;
          text-transform:capitalize;
        }
      }
    }
  }


}
@media screen and (max-width: 1199px) {
  .ttl-info{
    display: none;
  }
  input[type='checkbox'].tui-full-calendar-checkbox-round+span{
    margin-right: 2px;
    width: 4px;
  }
  .blog-list .blog-details {    
    p,hr{
      display: block;
    }
  }
  .profile-greeting {
    .header-top {
      text-align: center;
      .setting-list {
        float: none;
      }
    }
  }
  .alert-theme {
    max-width: 400px;
  }
  .vertical {
    .page-main-header {
      .main-header-right {
        .vertical-mobile-sidebar {
          display: block;
        }
      }
    }
  }
  .mega-menu-header {
    .main-header-right {
      .vertical-mobile-sidebar {
        display: block;
        margin-right: 15px;
      }
    }
  }
  .page-main-header {
    .main-header-right {
      .nav-right {
        &.right-menu {
          ul {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }
      .main-header-left {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }
  

  // sidebar css
  .page-wrapper {
    .page-main-header {
      .main-header-right {
        .nav-right {
          > ul {
            li {
              .submenu {
                li {
                  &:first-child {
                    padding-left: 15px !important;
                  }
                }
              }
            }
          }
        }
        .mobile-sidebar {
          .switch-sm {
            .switch {
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
  .page-main-header {
    .main-header-right {
      .left-menu-header {
        padding:24px 15px;
        flex:0 0 30%;
      }
      .nav-right {
        .nav-menus {
          padding: 20px 15px;
        }
      }
    }
  }

  // product page //
  .grid-options {
    ul {
      li {
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }

  // dashboard 2
  .dashboard-default-sec,.dashboard-2-main {
    .card {
      &.income-card {
        .card-body {
          .knob-block {
            canvas {
              width: 30%;
              height: 30%;
            }
          }
        }
      }
    }
  }

  // social app page
  .photos{
    ul{
      li{
        width:20%;       
      }
    }
  } 
  // timeline small page
  .timeline-small {
    .media {
      .timeline-round {
        &.timeline-line-1 {
          &:after {
            height: 33px;
            bottom: -40px;
          }
        }
        &.small-line {
          &:after {
            height: 28px;
            bottom: -38px;
          }
        }
        &.medium-line {
          &:after {
            height: 30px;
            bottom: -40px;
          }
        }
      }
      .media-body {
        h6 {
          font-weight: 600;
          margin-bottom: 30px;
          span {
            font-weight: normal;
            color: $light-text;
          }
        }
        p {
          width: 100%;
        }
      }
    }
  }

  // landing page css start
  .bg-gallery {
    height: 100vh;
  }
  .mt50 {
    margin-top: 30px;
  }
  .landing-main {
    .sticky {
      .navbar-expand-xl {
        .navbar-brand {
          top: 8px;
        }
        .navabr_btn-set {
          top: 16px;
        }
      }
    }
    .navbar-toggler {
      span {
        background-color: $white;
      }
    }
    .navbar_nav_modify {
      width: 100vw;
      left: 0;
      position: fixed;
      height: 100vh;
      background-color: #293240;
      top: 0;
      padding: 120px 0px;
    }
    h4 {
      font-size: 20px;
    }
    .navbar-expand-xl {
      .navabr_btn-set {
        position: fixed;
        top: 24px;
        z-index: 1;
        right: 20px;
      }
      .navbar-brand {
        padding-left: 5px;
        z-index: 1;
        position: fixed;
        top: 14px;
      }
      .navbar-collapse {
        .navbar-nav {
          .nav-item {
            a {
              padding: 28px 60px;
              width: 16px;
              margin: 0 auto;
              text-align: center;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
    .support-res {
      text-align: center;
      align-items: center;
      justify-content: center;
      padding: 20px;
    }
    .navbar-expand-lg {
      .navbar-brand {
        padding-left: 5px;
      }
      .navbar-collapse {
        .navbar-nav {
          .nav-item {
            a {
              padding: 9px;
            }
          }
        }
      }
    }
  }
  // landing page css ends
  .lg-mt-col {
    margin-top: 30px;
  }
  .xl-none {
    display: none;
  }
  .user-status {
    table {
      tbody {
        tr {
          td {
            .d-inline-block {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .lg-mt {
    margin-top: 30px;
  }
  .product-page-details {
    margin-top: 30px;
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-img {
          width: 32%;
        }
      }
    }
  }
  .debit-card {
    order: 1;
  }
  .typography {
    .row {
      div {
        & + div {
          margin-top: 30px;
        }
      }
    }
  }
  .listing {
    .card-body {
      .row {
        > div {
          & + div {
            margin-top: 30px;
          }
        }
      }
    }
  }
  .flot-chart-container {
    height: 250px;
  }
  .img-cropper {
    #putData {
      margin-bottom: 10px;
    }
    .docs-toggles {
      > .dropdown {
        margin-bottom: 0;
      }
    }
  }
  .error-wrapper {
    .maintenance-icons {
      li {
        i {
          color: $light-gray;
          font-size: 40px;
        }
        &:nth-child(2) {
          i {
            font-size: 80px;
            margin-left: 90px;
          }
        }
        &:nth-child(3) {
          i {
            font-size: 120px;
            margin-top: -75px;
            margin-right: 110px;
          }
        }
      }
    }
  }
  div {
    &.dataTables_wrapper {
      div {
        &.dataTables_paginate {
          & ~ .dataTables_filter {
            display: block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .jvector-map-height {
    height: 280px;
  }
  .custom-card {
    .card-profile {
      .card-social {
        li {
          a{
            padding: 8px;
          }
        }
      }
      img {
        height: 90px;
      }
      .card-footer {
        > div{
          h3{
            font-size:18px;
          }
        }
      }
    }
    .profile-details {
      h4 {
        font-size: 20px;
      }
      h6 {
        margin-bottom: 20px;
      }
      
    }
    .card-footer{
      > div {
        h3 {
          font-size: 18px;
        }
      }
    }   
  }
  .equal-height-lg {
    min-height: unset !important;
  }



  //mega menu
  .mega-menu {
    .onhover-show-div {
      height: 435px;
      overflow-x: scroll;
      left: 215px;
    }
    .mega-bg {
      display: none;
    }
    .lg-mt {
      margin-top: 25px;
    }
  }
  .button-builder {
    .button-generator-bx {
      position: relative;
      width: 100%;
    }
  }

  //blog
  .blog-single {
    .comment-box {
      ul {
        .comment-social {
          margin-left: 0;
        }
      }
    }
  }

  //error and maintenace
  .error-wrapper {
    .maintenance-heading {
      margin-top: 30px;
      .headline {
        font-size: 50px;
        margin-top: -15%;
        margin-bottom: 0px;
      }
      .cloud-second {
        margin-top: -25px;
      }
    }
  }





  

  // general widget
  .mobile-clock-widget {
    #date {
      margin-top: 15px;
    }
  }
  .social-widget-card {
    .b-b-light {
      padding: 15px;
      margin-bottom: 15px;
    }
  }
  .weather-widget-two {
    .bottom-whetherinfo {
      .whether-content {
        top: 34px;
      }
    }
  }
  .cal-date-widget {
    .cal-datepicker {
      margin-top: 15px;
    }
  }
  .static-top-widget {
    .media-body {
      .icon-bg {
        width: 70px;
        height: 70px;
      }
    }
  }
  .user-status {
    table {
      tbody {
        tr {
          th {
            min-width: 120px;
          }
          td {
            &:first-child {
              min-width: 295px;
            }
          }
        }
      }
    }
  }
  .testimonial {
    p {
      margin-top: 17px;
    }
  }
  .contact-form {
    padding: 10px;

    .theme-form {
      .form-icon {
        margin-top: -57px;
      }
    }
  }
  .calender-widget {
    .cal-date {
      width: 90px;
      height: 90px;

      h5 {
        font-size: 16px;
        padding: 18px;
      }
    }

    .cal-desc {
      padding: 14px;

      p {
        max-height: 114px;
        overflow-y: auto;
      }
    }
  }
  .browser-widget {
    padding: 0px;
    img {
      height: 50px;
    }
    .media {
      .media-body {
        h4 {
          font-size: 17px;
        }
      }

      .media-img {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
  .static-top-widget {
    .media-body {
      padding-left: 20px;
    }
  }
  .widget-joins {
    &:before {
      display: none;
    }

    .ps-0 {
      padding-left: 15px !important;
    }

    .pe-0 {
      padding-right: 15px !important;
    }

    .border-after-xs {
      &:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 94%;
        background-color: $light-semi-gray;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
  .widget-joins {
    .media {
      .media-body {
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  //ecommerce dashboard
  .crm-overall {
    .overall-chart {
      height: 200px;
    }
  }
  .product-page-main{
    .proorder-lg-1{
      order:-1;
    }
  }


  //chat
  .chat-box {
    .chat-menu {
      background-color:$white;     
      .people-list {
        ul.list {
          max-height:444px;
          overflow-y:auto;
        }
      }
    }
    .btn-lg {
      font-size: 12px;
    }
    .people-list {
      ul {
        height: auto;
      }
    }
    .chat-right-aside {
      .chat {
        .chat-header {
          height: auto;
          .chat-menu-icons li a i {
            font-size: 19px;
          }
        }
        .chat-message {
          margin: 0;         
          bottom: 15px;
        }
      }
    }
    .chat-history {
      .total-time h2 {
        font-size: 25px;
      }
      .row {
        .col-sm-7 {
          padding-right: 0;
        }
      }
    }
    .border-tab.nav-tabs {
      width: 100%;
      .nav-item {
        margin-right: 5px;
      }
    }
    .chat-left-aside {
      .people-list {
        height: 493px;
      }
    }
  }
  
  .chat-menu-icons {
    display: block;
    clear: both;
    width: 100%;
    text-align: right;
  } 

  
  // chat application
  .chat-box {
    .border-tab{
      &.nav-tabs {
        .nav-item {
          a{
            height: auto;
            line-height: 1.2;
          }
        }
      }
    }    
  }
  .chat-history {
    height: 447px;
  }
  .chat-box {    
    .toogle-bar {
      display:inline-block;
      margin-right: 0 !important;
    }
  }  
  // Email app
  .email-wrap {
    .email-right-aside {
      
      .email-body {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        .b-r-light {
          border-right: none !important;
        }
      }
      .radius-left {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
      }      
    }     
    .email-aside-toggle{
      display:block;
    }
    .email-sidebar{
      position: relative;
      .email-aside-toggle{
        margin-bottom:20px;
        width:fit-content;       
      }
      .email-left-aside{
        position:absolute;
        top:100%;
        left:0;
        opacity:0;
        visibility:hidden;
        z-index: 2;
        width:300px;
        transition: all 0.5s ease;
        &.open{
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  .job-accordion {
    
    .card{
      margin-bottom:20px;
    }
  }
  .job-sidebar{
    position: relative;
    .job-toggle{
      display:block;
      margin-bottom:20px;
    }    
    .job-left-aside{
      position:absolute;
      top: 100%;
      left: 0;
      margin-top: 20px;
      opacity: 1;
      visibility: hidden;
      overflow-y: scroll;
      z-index: 3;
      max-width: 270px;
      height:600px;
      background-color: $white;
      border: 1px solid $light-gray;
      padding: 20px;
      &.open{
        opacity:1;
        visibility: visible;
      }
    }
  }
  .learning-block{
    >div{
      &:first-child{
        order:2;
      }
    }
  }

  // icons
  .icon-lists {
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 209px;
    }
  }
  // tour page
  .like-comment-lg-mb {
    margin-bottom: 12px;
  }
  // ribbons page
  .ribbon-space-bottom {
    top: 9px;
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 98.5% !important;
      }
    }
  }

  // jsgrid-table page
  #batchDelete {
    .jsgrid-grid-header {
      .jsgrid-table {
        .jsgrid-header-row {
          .jsgrid-header-cell {
            &:first-child {
              width: 95px !important;
            }
          }
        }
      }
    }
    .jsgrid-grid-body {
      .jsgrid-table {
        .jsgrid-row {
          .jsgrid-cell {
            &:first-child {
              width: 95px !important;
            }
          }
        }
      }
    }
  }
  // user-profile page
  .profile-img-style {
    #aniimated-thumbnials-3 {
      text-align: center;
    }
  }
  .user-profile{
    .photos{
      ul{
        li{
          width:20%;
        }
      }
    } 
    .profile-header{
      height:380px;
      .userpro-box{
        max-width:325px;
        padding:25px;
        .icon-wrapper{
          width:30px;
          height:30px;
          font-size:13px;
        }
        .avatar{
          width:85px;
          height:85px;
        }
        h4{
          font-size: 18px;
        }
        h6{
          font-size: 14px;
        }
        
      }
    }
    .social-media {
      ul{
        &.user-list-social {
          li {
            a{
              width:28px;
              height:28px;
            }
          }
        }
      }
    }
  }
  .social-tab {
    padding:20px;
    ul{
      li{
        a{
          padding: 8px 10px;
          font-size: 12px;
        }
      }
    }
    .input-group{
      .form-control{
        height:35px;
      }
    }
  }

  // vertical page
  .vertical-menu-mt {
    margin-top: 80px !important;
    .xl-none {
      display: block;
    }
  }
  .sm {
    position: fixed;
    background-color: $white;
    width: 300px;
    border-radius: 0;
    border: 1px solid $light-gray;
    top: 80px;
    height: 100vh;
    left: -300px;
    z-index: 99;
    transition: all 0.3s ease;
    overflow: scroll;
  }
  // calender page
  .calendar-wrap {
    .fc-day-grid-event {
      width: 87%;
    }
  }


  


}
@media screen and (max-width: 1120px) {
  .bg-gallery {
    height: 94vh;
  }


  .social-tab {
    .input-group{
      width:190px;
    }
  }


  
}
@media screen and (max-width: 1024px) {
  .bg-gallery {
    height: 90vh;
  }
  .bg-sky-blue {
    .main-landing {
      .position-cards {
        .landing-card-9 {
          left: 16%;
          top: 8%;
          width: 13%;
        }
        .landing-square-2 {
          left: 13%;
          top: 6%;
        }
        .landing-card-2 {
          left: 32%;
          top: 3%;
          width: 30%;
        }
        .landing-card-4 {
          right: 16%;
        }
        .landing-card-5 {
          top: 11%;
        }
        .landing-card-3 {
          left: 3%;
          bottom: 1%;
          width: 32%;
          right: unset;
          top: unset;
        }
        .landing-card-10 {
          right: 5%;
          top: 36%;
          width: 40%;
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {

  .page-wrapper{
    &.compact-wrapper {
      .page-body-wrapper {
        header{
          &.main-nav{
            .main-navbar{
              .nav-menu{
                height: calc(100vh - 220px);
              }
            }
          }
          .sidebar-user{
            padding: 20px 30px;
            .setting-primary{
              top: 20px;
            }
            img{
              width: 35px !important;
              border-width: 3px !important;
              float: left;
            }
            .badge-bottom{
              display: none;
            }
            h6{
              margin-top: 0 !important;
              padding-left: 50px;
              text-align: left;
              display: block;
            }
            p{
              padding-left: 15px;
              text-align: left;
              max-width: 120px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            ul{
              margin-top: 10px;
              border-top: 1px $gray-60 dashed;
              padding-top: 10px;
            }
          }
          &.main-nav{
            top:65px;
          }
        }
      }
    }
  }
  .ttl-info {
    display: block;
} 
  .page-wrapper {
    .page-body-wrapper {
      .page-body {
        // padding-top: 0 !important;
        min-height: calc(100vh - 65px);
        margin-top: 65px;
      }
    }
  }
  .customizer-contain {
    top: 62px;
  }
  .mobile-clock-widget {
    #date {
      margin-top: 20px;
    }
  }
  .m-r-30 {
    margin-right: 20px;
  }
  .card.full-card {
    top: 60px;
    width: calc(100vw - 0px);
  }
  .card {
    .sub-title {
      font-size: 16px;
    }
    .card-header {
      padding: 20px;
      h5 {
        font-size: 18px;
      }
    }
    .card-body {
      padding: 20px;
    }
    .card-footer {
      padding: 20px;
    }
  }
  .setting-list {
    top: 18px;
    .setting-option {
      height: 26px;
      li {
        &:first-child {
          i {
            font-size: 14px;
            &.icon-angle-double-right {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .setting-primary,
  .setting-secondary,
  .setting-white {
    width: 26px;
    height: 26px;
  }
  .trasaction-sec {
    .transaction-totalbal {
      padding-left: 20px;
    }
  }
  .document {
    &.page-wrapper.horizontal-wrapper {
      .page-main-header {
        &.document-header {
          height: 60px;
        }
      }
    }
  }
  .document-content {
    .page-body {
      margin-top: 90px !important;
    }
    ul {
      &.nav-pills {
        max-height: calc(100vh - 84px);
      }
    }
    .logo-wrapper {
      display: none;
    }
  }
  .vertical-mobile-sidebar {
    right: 75px;
    top: 18px;
  }
  .sm {
    top: 80px;
  }
  .bg-gallery {
    height: 85vh;
  }
  .bg-sky-blue {
    .main-landing {
      .landing-main-content {
        margin: 0 auto;
        text-align: center;
        width: 100%;
        img {
          display: unset !important;
        }
      }
      .position-cards {
        .landing-card-3 {
          left: 3%;
          bottom: 14%;
          top: unset;
        }
        .landing-card-10 {
          left: 0%;
          top: 3%;
          width: 35%;
        }
        .landing-card-8 {
          left: 38%;
          bottom: 18%;
          width: 15%;
        }
        .landing-square-1 {
          bottom: 18%;
          left: 38%;
        }
        .landing-card-7 {
          right: 17%;
          bottom: 13%;
        }
        .landing-card-6 {
          right: -4%;
          bottom: 15%;
        }
        .landing-card-9 {
          left: 34%;
          top: 13%;
          width: 10%;
        }
        .landing-square-2 {
          left: 32%;
          top: 11%;
        }
        .landing-card-2 {
          right: 30%;
          top: 10%;
          width: 24%;
          left: unset;
        }
        .landing-card-5 {
          right: -3%;
        }
      }
    }
  }
  .viho-demo-section {
    .gallery_content,
    .viho-demo-content {
      p {
        width: 70% !important;
      }
    }
  }
  .landing_gallery_section_img {
    margin-bottom: -60px;
    .img-gallery-set3,
    .img-gallery-set2,
    .img-gallery-set4 {
      bottom: 80px;
    }
    &:before {
      height: 500px;
    }
  }
  .landing-main {
    .navbar-expand-lg .navbar-collapse {
      position: absolute;
      top: 90px;
      background-color: rgb(255, 255, 255);
      width: calc(100% - 30px);
      border-radius: 5px;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      padding: 15px;
    }
  }
  .gallery {
    margin-bottom: -20px;
  }
  .my-gallery {
    figure {
      margin-bottom: 20px;
    }
  }
  #aniimated-thumbnials {
    figure {
      &:nth-child(12),
      &:nth-child(11),
      &:nth-child(10),
      &:nth-child(9) {
        margin-bottom: 20px;
      }
    }
  }
  .photoswipe-pb-responsive {
    padding-bottom: 25px !important;
  }


  // user-profile
  .user-profile{
    .photos{
      ul{
        li{
          width:19%;
        }
      }
    }
    .profile-post {
      .post-header {
        .media {
          img{
            width:50px;
            height:50px;
          }
          h5{
            font-size:16px;
          }
          h6{
            font-size:12px;
          }
        }
      }
    }
  }
 
  



  // main header css
  .page-wrapper {
    .section-space {
      padding: 60px 0;
    }
    .card {
      .card-header,
      .card-body,
      .card-footer {
        // padding: 20px;
        .tab-content {
          .m-t-30 {
            margin-top: 20px !important;
          }
          .m-b-30 {
            margin-bottom: 20px !important;
          }
        }
      }
    }
    .page-body-wrapper {
      .page-header {
        // padding-top: 25px;
        padding-bottom: 25px;
      }
      .footer-fix {
        margin-left: 0px;
        padding-right: 15px;
        width: calc(100% - 0px);
      }
    }
    &.compact-wrapper {
      .page-main-header {
        margin-left: 0 !important;
        width: calc(100% - 0px) !important;
        .main-header-right {
          .logo-wrapper {
            display: block;
          }
          .toggle-nav {
            display: none;
          }
        }
      }
      .page-body-wrapper{
        &.sidebar-icon{
          header{
            &.main-nav{
              top: 59px ;
              box-shadow: 0 4px 14px 0 rgba(4, 122, 251, 0.13);
              .logo-wrapper {
                display: none;
              }
              .sidebar-user {
                padding: 20px;
                .badge-bottom {
                  top: 86px;
                }
              }
              .main-navbar {
                .nav-menu {
                  height: calc(100vh - 304px);
                }
              }
              &.close_icon {               
                .main-navbar{
                  .nav-menu{
                    height: unset;
                  }
                }
              }
            }
          }         
        }
      }      
    }
    .page-main-header {
      .main-header-right {
        margin: 0;
        .nav-right {
          .notification {
            top: 10px;
          }
          > ul {
            top: 58px;
            position: fixed;
            z-index: -1;
            background-color: $white;
            transition: all linear 0.3s;
            border-top: $card-border-color;
            box-shadow: 0 2px 2px 2px $common-shadow-color;
            width: 100%;
            left: 0;
            padding: 0 20px;
            transform: translateY(-35px) scaleY(0);
            opacity: 0;
            visibility: hidden;
            height: 70px;
            &.open {
              z-index: 1;
              opacity: 1;
              transform: translateY(0px) scaleY(1);
              visibility: visible;
            }
          }
        }
        > .mobile-toggle {
          display: flex;
          padding-right: 20px;
        }
        .main-header-left {
          margin-right: 0;
          padding: 15px 20px;
        }
      }
      .main-header-left {
        .logo-wrapper {
          img {
            margin-top: 0;
          }
        }
      }
      .left-menu-header {
        padding: 15px 20px;
        ul {
          li {
            i {
              display: inline;
            }
          }
        }
      }
    }   
    &.compact-sidebar{
      &.compact-wrapper{
        .page-body-wrapper{
          header{
            &.main-nav{
              .main-navbar{
                .nav-menu{
                  height: calc(100vh - 80px);  
                  padding-top: 20px;
                  padding-bottom: 20px;
                  .dropdown{
                    .menu-content{
                      top:60px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .page-body-wrapper {
    .page-body,
    footer {
      margin-left: 0 !important;
      #customer-review {
        .owl-stage-outer {
          width: 100% !important;
        }
      }
      .calender-widget {
        .cal-desc {
          p {
            max-height: 101px;
          }
        }
      }
    }
  }
  .product-page-details {
    h3 {
      font-size: 22px;
    }
  }

  // bookmark //
  .bookmark {
    ul {
      li {
        a {
          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }

  // general widget page
  .crm-activity {
    > {
      li {
        + li {
          margin-top: 10px;
          padding-top: 10px;
        }
      }
    }
  }

  // add post page
  .add-post {
    form {
      .form-group {
        margin-bottom: 20px;
      }
    }
    .dropzone {
      margin-bottom: 20px;
    }
  }

  // avatar page
  .customers {
    &.avatar-group {
      margin-right: 20px;
    }
  }

  // internationalization page css
  .main {
    .langChoice {
      right: 20px;
      top: 11px;
    }
  }

  //social app page css
  .socialprofile {
    .social-btngroup {
      margin: 20px 0;
    }
    .social-group {
      margin-top: 20px;
    }
  }
  .social-status {
    form {
      .form-group {
        .form-control-social {
          margin-bottom: 20px;
        }
      }
    }
    .media {
      margin-bottom: 20px;
    }
  }
  .timeline-content {
    p,
    .comments-box {
      margin-top: 20px;
    }
  }
  .social-chat {
    margin-top: 20px;
    .media-body {
      padding: 20px;
    }
    .your-msg,
    .other-msg {
      margin-bottom: 20px;
    }
  }
  .social-network {
    span {
      margin-bottom: 20px;
    }
  }
  .details-about {
    + .details-about {
      margin-top: 20px;
    }
  }
  .activity-log {
    .my-activity {
      + .my-activity {
        margin-top: 20px;
      }
    }
  }
  .new-users-social {
    margin-bottom: 20px;
  }

  // timeline small page css
  .timeline-small {
    .media {
      margin-bottom: 20px;
      .media-body {
        h6 {
          margin-bottom: 20px;
        }
        p {
          width: 75%;
        }
      }
      .timeline-round {
        width: 45px;
        height: 45px;
        top: -14px;
        &.timeline-line-1 {
          &:after {
            bottom: -41px;
            height: 25px;
          }
        }
        &.small-line {
          &:after {
            bottom: -26px;
            height: 14px;
          }
        }
        &.medium-line {
          &:after {
            bottom: -33px;
            height: 17px;
          }
        }
        svg {
          top: 14px;
        }
      }
    }
  }

  // landing page css start
  .landing-main {
    .section-space {
      padding: 60px 0;
      p {
        font-size: 15px;
      }
    }
    .navbar-expand-lg {
      .navbar-toggler {
        background-color: $white;
        .navbar-toggler-icon {
          i {
            margin-top: 5px;
            color: $primary-color;
          }
        }
      }
      .navbar-collapse {
        background-color: $white;
        z-index: 111;
        .navbar-nav {
          .nav-item {
            a {
              color: $black;
            }
          }
        }
      }
    }
    .footer-bg {
      h2 {
        font-size: 28px;
      }
    }
  }

  // landing page css ends
  // helper classes page start
  .helper-classes {
    padding: 20px;
  }

  // helper classes page ends
  .floated-customizer-btn,
  .floated-customizer-panel {
    display: none;
  }
  .page-builder {
    .ge-canvas {
      &.ge-editing {
        .row {
          padding: 20px;
        }
      }
    }
  }
  .weather-widget-two {
    .bottom-whetherinfo {
      .whether-content {
        top: -15px;
      }
    }
  }
  .social-widget-card {
    .b-b-light {
      padding: 20px;
      margin-bottom: 20px;
    }
  }
  .ecommerce-widget {
    .progress-showcase {
      margin-top: 20px;
    }
  }
  .crm-overall {
    margin: 0 -20px -20px;
  }
  .product-page-main {
    padding: 20px;
  }
  .grid-options {
    ul {
      li {
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
  .feature-products {
    form {
      .form-group {
        i {
          right: 20px;
        }
      }
    }
  }
  .tabbed-card ul {
    padding: 18px 15px;
  }

  .tabbed-card {
    ul {
      top: -5px;
      &.border-tab {
        &.nav-tabs {
          padding: 0;
          .nav-item {
            .nav-link {
              padding: 18px 15px;
            }
          }
        }
      }
    }
  }
  .tilt-showcase {
    .tilt-image {
      text-align: center;
    }
    .mt-4 {
      margin-top: 20px !important;
    }
  }
  .todo {
    .todo-list-wrapper {
      .mark-all-tasks {
        top: 20px;
        right: 20px;
      }
    }
  }
  .custom-card {
    padding-bottom: 0;
    .card-footer {
      // padding: 0 15px 15px 15px!important;
      padding: 0;
    }
    .card-header {
      border: none;
    }
    .dashboard-card {
      padding: 0;
    }
  }
  .display-1 {
    font-size: 4rem;
  }
  .display-2 {
    font-size: 3.5rem;
  }
  .display-3 {
    font-size: 2.5rem;
  }
  .display-4 {
    font-size: 1.5rem;
  }

  .typography {
    .h1 {
      font-size: 34px;
    }
    .h2 {
      font-size: 30px;
    }
    .h3 {
      font-size: 26px;
    }
    .h4 {
      font-size: 22px;
    }
    .h5 {
      font-size: 18px;
    }
    .h6 {
      font-size: 15px;
    }
  }
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 18px;
  }
  .flot-chart-container {
    height: 300px;
  }
  .card-absolute {
    .card-header {
      padding: 10px 15px !important;
    }
  }

  .comingsoon {
    .comingsoon-inner {
      .countdown {
        .time {
          width: 80px;
          height: 80px;
          font-size: 26px;
        }
      }
    }
  }

  footer {
    bottom: -20px;
  }
  .calendar-wrap {
    .basic-calendar {
      .external-events {
        .fc-event {
          & + .fc-event {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .owl-theme {
    .owl-nav {
      &.disabled {
        & + .owl-dots {
          margin-bottom: -10px;
          margin-top: 15px;
        }
      }
    }
  }
  .card {
    .card-header {
      .card-header-right {
        top: 11px;
        right: 11px;
      }
    }
  }
  .page-builder {
    .ge-addRowGroup {
      width: 100%;
    }
  }

  
  //blog 
  .comment-box {
    .comment-social {
      li{
        &:nth-child(n+2){
          padding-left:15px;
          margin-left:15px;
        }
      }
    }
  }

  //error and maintenace
  .error-wrapper {
    padding: 35px 0;
    .sub-content {
      font-size: 14px;
      line-height: 25px;
      margin-top: 0px;
    }
    .error-heading {
      margin-top: 30px;
      .headline {
        font-size: 180px;
        margin-top: 0;
      }
    }
    .maintenance-heading {
      margin-top: 30px;
    }
  }

  //search
  .search-page {
    .info-block {
      flex-wrap: wrap;
    }
    .tab-content {
      .pb-4 {
        padding-bottom: 20px !important;
      }
    }
  }

 //business dashboard
  .calender-widget {
    .cal-desc {
      padding: 10px 20px 20px !important;
      p {
        max-height: 100px;
      }
    }
  }
  .contact-form {
    padding-top: 10px !important;
    .theme-form {
      padding: 20px;
      margin-top: 27px;
      .form-icon {
        margin-top: -47px;
      }
    }
  }
  //crm dashboard
  .user-status {
    &.product-chart {
      max-height: 518px;
      overflow: auto;
    }
  }
  // general widget
  .widget-joins {
    .media {
      .media-body {
        i {
          float: none !important;
        }
      }
    }
  }
  .xl-none {
    display: block;
  }
  //blog
  .top-radius-blog {
    width: 100%;
  }
  .sm-100-w {
    width: 100%;
    height: 100%;
  }
  /*Form Wizard One start*/
  .form-wizard {
    .btn-mb {
      margin-bottom: 20px;
    }
  }
  /*Form Wizard One ends*/

  /*Form Wizard Two Start*/
  .stepwizard {
    margin-bottom: 20px;
  }
  /*Form Wizard Two Ends*/

  /*Form Wizard Three Start*/
  .f1 {
    .f1-steps {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  /*Form Wizard Three ends*/

  /*Form Wizard Four Start*/
  .wizard-4 {
    ul.anchor {
      padding-right: 20px;
    }
  }
  /*Form Wizard Four ends*/
  // tab material
  .border-tab.nav-tabs {
    margin-bottom: 20px;
  }
  .tab-content {
    ~ .nav-tabs {
      &.border-tab {
        margin-top: 20px;
      }
    }
  }

  // support ticket
  .dataTables_wrapper {
    .dataTables_length {
      margin-bottom: 20px;
    }
    table.dataTable {
      margin-bottom: 20px !important;
    }
  }
  // search page
  .search-page {
    .info-block {
      + .info-block {
        padding-top: 20px;
        margin-top: 20px;
      }
    }
    #video-links {
      .embed-responsive + .embed-responsive {
        margin-top: 20px;
      }
    }
  }
  .lg-mt {
    margin-top: 20px;
  }
  .gallery-with-description {
    margin-bottom: -20px;
    a {
      > div {
        margin-bottom: 20px;
      }
    }
  }
  // clipboard
  .clipboaard-container {
    p {
      margin-bottom: 8px;
    }
  }
  // summer-note
  .m-b-30 {
    margin-bottom: 20px !important;
  }

  // navs page
  .nav-md-mt {
    margin-top: 20px;
  }
  .navs-icon {
    padding: 20px;
    .main-section {
      padding-top: 20px;
    }
    .separator {
      margin: 20px 0;
    }
  }
  .nav-list {
    padding: 20px;
  }
  .navs-dropdown {
    button {
      margin-top: 20px;
    }
    .onhover-show-div {
      top: 68px;
    }
  }
  // animated modal page
  .animated-modal {
    .form-group {
      width: 100%;
      display: block;
    }
    .me-4 {
      margin-right: 0 !important;
    }
    .animated-modal-md-mb {
      margin-bottom: 20px !important;
    }
  }
  // steps page
  .steps-md-mt {
    margin-top: 20px;
  }
  // button group page
  .btn-group-wrapper {
    .m-b-30 {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
  // datatable advance page
  #advance-5_wrapper {
    .dataTables_paginate {
      margin-bottom: 20px;
    }
  }
  // summer-note page
  .click2edit {
    & ~ .note-editor {
      &.note-frame {
        margin-bottom: 20px;
      }
    }
  }

  .summer-note {
    .card-header,
    .card-body {
      padding: 20px;
    }
  }
  // call-chat page
  .chat-box{
    .chat-left-aside {
      .people-list {
        height: 483px;
      }
    }
    .chat-menu{
      top:75px;
      .user-profile {
        .user-content{
          h5{
            margin-bottom:10px;
          }
        }
        .follow {
          span{
            font-size:12px;
          }
          .follow-num{
            font-size: 18px;
          }
        }
      }
    }

    
  }

}
@media screen and (max-width: 900px) {
  .bg-gallery {
    height: 80vh;
  }
}
@media screen and (max-width: 800px) {
  .bg-gallery {
    height: 75vh;
    min-height: 560px;
  }
}
@media screen and (max-width: 767px) {
  .page-wrapper.compact-wrapper{
      .page-body-wrapper{
        header.main-nav{
          .sidebar-user{
            padding: 20px;
            ul{
              display: none;
            }
          }
          .main-navbar {
            .nav-menu {
              height: calc(100vh - 220px);
            }
          }
        }
      }
    }

  .order-history table tbody tr td img{
    width: 25px !important;
  }
  .calendar-basic .lnb-calendars>div{
    padding: 12px 0;
  }
  .learning-box .details-main {
    margin: 20px;
  }
  .ace-editor {
    height: 280px;
    width: 100%;
}
  .dashboard-2-main .card .card-header p {
    font-size: 0;
  }
  .card-social {
    margin-top: 30px;
  }
  // button css //
  .btn-showcase {
    text-align: center;
  }
  .form-inline {
    .form-group {
      + .form-group {
        margin-top: 10px;
      }
    }
  }
  .credit-card {
    .text-center {
      img {
        margin-bottom: 20px;
      }
    }
  }
  .starter-kit-fix {
    .page-body {
      margin-bottom: 78px;
    }
  }
  .navbar-toggler {
    position: absolute;
    right: 0;
    top: 10px;
  }
  .document {
    .page-main-header {
      // background-color: $primary-color;
      .document-right-nav {
        .navbar {
          .navbar-collapse {
            .navbar-nav {
              .nav-item {
                .nav-link {
                  color: $primary-color !important;
                }
              }
            }
          }
        }
      }
    }
    .navbar-collapse {
      position: absolute;
      width: 100%;
      background-color: $white;
      top: 60px;
      padding: 15px;
      left: 0;
      right: 0;
    }
  }
  .products-total {
    margin-bottom: 10px;
  }
  .page-main-header {
    .main-header-right {
      .left-menu-header {
        ul {
          li {
            .search-form {
              input {
                width: 90% !important;
              }
            }
          }
        }
      }
      .main-header-left {
        width: auto;
        .toggle-sidebar {
          margin-left: 30px;
        }
      }
    }
  }
  .product-page-main {
    .border-tab.nav-tabs .nav-item {
      width: auto !important;
    }
  }
  .blog-box {
    .blog-details-main {
      .blog-social {
        li {
          padding: 0 12px;
        }
      }
    }  
  }
  .footer-copyright {
    ~ div {
      text-align: center;
      p {
        float: none;       
      }
    }
  }

  // calender
  .event-calendar {
    display: block !important;
    #right {
      width: 100%;
    }
  }
  #lnb {
    width: auto;    
  }
  .ecommerce-widget {
    .progress-showcase {
      .progress {
        height: 15px;
      }
    }
  }



  // user profile //
  .user-profile {
    .photos {
      ul {
        li{
          width:55px;
        }
      }
    }
    .profile-header{
      height:355px;
      .userpro-box{
        padding:20px;
        max-width:300px;
        .avatar{
          width:70px;
          height:70px;          
        }
        h4{
          font-size:16px;
        }
        h6{
          font-size:13px;
        }
      }
     }        
  }
  .social-tab{
    display:block;
    ul{
      justify-content:center;
      margin-bottom:-10px;
      li{
        display: inline-block;
        margin-bottom: 10px;
      }
    }
    .input-group{
      margin-top:15px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  .social-app-profile{
    .social-status {     
      .media {
        img{
          width:40px !important;
        }
        .social-status{
          bottom:10px;
          left:35px;
        }
      }
    }    
  }
  


  // general-widget
  .general-widget{
    .custom-card{
      padding:0;
      .card-profile{
        margin-top:-45px;
      }
      .card-header{
        padding:0;
        img{
          margin-top:0;
        }
      }
      .card-social{
        margin-top: unset;
      }
    }
  }

  // social app page css  
  .tabs-scoial {
    &.border-tab {
      &.nav-tabs {
        display: flex;
      }
    }
  }
  .edit-profile {
    .card {
      .card-header {
        h4 {
          font-size: 22px;
        }
      }
    }
    .profile-title {
      .media {
        .media-body {
          h3 {
            font-size: 20px;
          }
        }
      }
    }
  }
  

  // card css//
  .tabbed-card {
    ul {
      padding: 10px 0 5px 0;
      position: relative;
      width: 100%;
      left: 0;
    }
  }

  // search page //
  .search-page {
    .search-vid-block {
      > div {
        &:nth-child(n + 2) {
          margin-top: 20px;
        }
      }
    }
  }

  // product page //
  .product-page-details {
    h3 {
      font-size: 18px;
    }
  }
  .filter-block {
    h4 {
      font-size: 18px;
      margin-bottom: 15px;
    }
    ul {
      li {
        &:nth-child(n + 2) {
          margin-top: 10px;
        }
      }
    }
  }

  // dashboard main //
  .center-content {
    p {
      text-align: center;
      span {
        display: block;
      }
    }
  }

  // timeline small page css
  .timeline-small {
    .media {
      .media-body {
        p {
          width: 100%;
        }
      }
    }
  }

  // icon-compact layout page
  .my-gallery {
    figure {
      &:nth-child(9),
      &:nth-child(10) {
        margin-bottom: 20px;
      }
    }
  }

  // chat
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-message {
          .text-box {
            .btn {
              font-size: 0;
              &:before {
                position: absolute;
                content: "\e661";
                font-family: $font-themify;
                font-size: 18px;
                top: 11px;
                left: 20px;
              }
            }
          }
        }
      }
      
    }
    .border-tab {
      &.nav-tabs {
        display: flex;
      }
    }
    .chat-menu {
      .nav-tabs {
        .nav-item {
          a {
            height: 50px;
          }
        }
      }
    }
    .people-list {      
      ul {
        margin-bottom:-15px;
        li{
          display: inline-block;
          padding: 10px;
          margin-right:5px;
          border: 1px solid $light-gray;
          margin-bottom:10px;
          &:last-child{
            padding-bottom:10px;
          }
        }
      }
    }
    .chat-left-aside {
      .people-list{
        height:auto;
      }
    }
  }

  
  
  .clockpicker-align-top {
    left: 55px !important;
    top: 470px !important;
    .arrow {
      display: none;
    }
  }
  .form-builder-column {
    .form-body {
      min-height: 250px;
    }
  }
  .form-builder-2-header {
    flex-wrap: wrap;
    > div {
      width: 100%;
      margin-top: 10px;
      nav {
        float: none;
      }
    }
  }

  // advance pages //
  .dropzone.dz-clickable {
    padding: 20px;
  }

  // form-builder
  .lg-mt-col {
    margin-top: 20px;
  }
  .form-builder {
    .drag-box {
      fieldset {
        padding: 20px;
        margin-top: 20px;
      }
    }
    #components {
      margin-top: 20px;
    }
    .component {
      label {
        padding-top: 0px;
      }
    }
  }

  //mega menu
  .mega-menu {
    .onhover-show-div {
      height: 415px;
      left: 0;
      top: 61px;
      padding: 20px;
    }
    .lg-mt {
      margin-top: 15px;
    }
  }

  // landing page css start
  .bg-gallery {
    min-height: 530px;
  }
  .landing_gallery_section_img {
    &:before {
      height: 400px;
    }
  }
  .page-wrapper {
    .section-space {
      padding: 30px 0;
    }
  }
  .and-many-more {
    font-size: 65px;
  }
  .container-modify {
    .img-effect {
      max-width: 320px;
      margin: 0 auto;
    }
  }
  .bg-sky-blue {
    .main-landing {
      .position-cards {
        .landing-card-9 {
          left: 12%;
          top: 33%;
          width: 12%;
        }
        .landing-square-1 {
          top: 32%;
          right: 9%;
          bottom: unset;
          left: unset;
        }
        .landing-card-8 {
          right: 9%;
          top: 32%;
          left: unset;
          bottom: unset;
        }
        .landing-square-2 {
          left: 9%;
          top: 31%;
        }
        .landing-card-7 {
          right: 21%;
          width: 40%;
        }
        .landing-card-2 {
          right: 44%;
        }
      }
    }
  }
  .viho-demo-section {
    .viho-demo-content,
    .gallery_content {
      img {
        width: 30px !important;
      }
      p {
        width: 100% !important;
      }
    }
  }
  .and-more {
    top: 40%;
  }
  .landing-main {
    .section-space {
      padding: 40px 0;
    }
    .main-landing {
      padding: 27px 0 0 0;
    }
  }
  // landing page css ends
  .page-builder {
    .ge-canvas {
      &.ge-editing {
        .ge-tools-drawer {
          margin: 10px 0 0 10px;
          > a {
            padding: 0 5px;
          }
        }
      }
    }
  }
  .reader {
    margin-top: 15px;
    height: auto !important;
  }
  .range-slider {
    &.theme-form {
      .form-group {
        margin-bottom: 0;
      }
    }
  }
  .note-editor {
    .btn-group {
      .btn {
        font-size: 11px;
      }
    }
  }
  .user-status {
    tbody {
      tr {
        td {
          min-width: 284px;
          & + td {
            min-width: unset;
            & + td {
              min-width: 100px;
            }
          }
        }
      }
    }
  }
  .text-md-end {
    text-align: right;
  }
  .star-ratings {
    .stars {
      padding: 0;
    }
  }
  .form-inline {
    &.d-inline-block {
      display: block !important;
      width: 100%;
      .form-control {
        margin-bottom: 10px;
      }
    }
  }
  .reset-password-box {
    width: 500px;
    margin: 0 auto;
    .card {
      padding: 20px;
    }
  }
  .user-card{
    .custom-card{
      padding:0;     
      .card-header{
        padding:0;
      } 
      .card-profile{
        margin-top: -40px;
      }
      .card-social{
        margin-top: unset;
      }
    }
  }
  .custom-card{
     .card-header{
       
        img{
          transform: scale(1);
        }
     }
    .card-profile {
      img {       
        height: 75px;
      }
    }
    .profile-details {
      h4 {
        font-size: 18px;
      }
    }
    .card-footer {
      > div {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
  .error-wrapper {
    .maintenance-icons {
      li {
        i {
          color: $light-gray;
          font-size: 30px;
        }
        &:nth-child(2) {
          i {
            font-size: 60px;
            margin-top: -10px;
            margin-left: 70px;
          }
        }
        &:nth-child(3) {
          i {
            font-size: 90px;
            margin-right: 80px;
          }
        }
      }
    }
  }
  .authentication-main {
    .auth-innerright {
      .social-media {
        li {
          font-size: 14px;
        }
      }
      min-height: unset;
      padding: 0;
    }
  }
  .loader-box {
    justify-content: center;
  }
  .dataTables_wrapper {
    .dataTables_length {
      margin-bottom: 20px;
    }
    .dataTables_filter {
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
  div {
    &.dataTables_wrapper {
      div {
        &.dataTables_info {
          padding-top: 0;
        }
        &.dataTables_length,
        &.dataTables_filter {
          label {
            float: none;
          }
        }
        &.dataTables_paginate {
          float: none;
          margin-top: 20px !important;
          margin-left: 0 !important;
        }
      }
    }
  }
  .calendar-wrap {
    .fc-basic-view {
      .fc-day-number {
        padding: 0 0px !important;
      }
    }
    .fc-events-container {
      margin-top: 0px;
    }
    .fc-events-container {
      h6,
      .checkbox {
        text-align: right;
      }
    }
    .checkbox {
      label {
        margin-top: 0;
      }
    }
    .fc-toolbar {
      .fc-left {
        .fc-next-button {
          .fc-icon-right-single-arrow {
            &:after {
              font-size: 12px !important;
            }
          }
        }
        .fc-prev-button {
          .fc-icon-left-single-arrow {
            &:after {
              font-size: 12px !important;
            }
          }
        }
      }
      .fc-left,
      .fc-right {
        .fc-today-button {
          padding-top: 0px !important;
        }
      }
    }
    .fc {
      button {
        font-size: 14px !important;
        padding: 0 5px !important;
      }
      .fc-left {
        margin-bottom: 10px;
      }
      .fc-toolbar {
        > div {
          display: block !important;
          float: none !important;
        }
        > * {
          :first-child {
            font-size: 18px;
          }
          > * {
            float: none !important;
          }
        }
      }
      margin-top: 0px;
    }
  }
  .btn-group-wrapper {
    text-align: center;
    .m-b-30 {
      margin-bottom: 15px !important;
    }
  }

  .btn-group-showcase {
    text-align: center;
    .btn-group {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  .btn-group {
    .btn {
      font-size: 14px;
      padding: 0.375rem 1.3rem;
    }
  }
  .animate-widget {
    .text-center {
      display: none;
    }
  }
  //general widget page
  .calender-widget {
    .cal-desc {
      p {
        max-height: 100%;
      }
    }
  }
  .user-status {
    &.product-chart {
      max-height: 100%;
    }
    table {
      thead {
        tr {
          th {
            &:nth-child(3) {
              min-width: 124px;
            }
          }
        }
      }
    }
  }
  //mega menu
  .mega-menu {
    .onhover-show-div {
      height: 350px;
    }
  }

  .page-builder {
    .ge-canvas {
      .column {
        padding: 0 !important;
      }
    }
    .ge-content-type-ckeditor {
      h3 {
        font-size: 20px;
      }
      p {
        font-size: 12px;
      }
    }
    .ge-row-icon {
      display: none !important;
    }
  }

  /* font-awesom icon page */
  .icon-hover-bottom {
    .form-group {
      input {
        min-width: 270px;
      }
    }
  }
  

  //calendar
  .calendar-wrap {
    .fc {
      button {
        padding: 5px;
      }
    }
    .fc-day-grid-event {
      margin: 0;
      padding: 5px;
      width: inherit;
    }
    .fc-toolbar {
      h2 {
        font-size: 20px;
        line-height: 35px;
        font-weight: bold;
      }
    }
    .basic-calendar {
      .external-events {
        margin-top: 0px;
      }
    }
  }

  //blog
  .blog-single {
    .comment-box {
      .media {
        h6 {
          margin-bottom: 10px;
        }
        img {
          margin-right: 30px;
        }
      }
      .comment-social {
        margin-bottom: 10px;        
      }
    }
  }

  //search
  .search-page {
    #image-links {
      .info-block {
        &.m-t-30 {
          margin-top: 0 !important;
        }
      }
    }
  }

  //authentication
  .authentication-main {
    .authentication-box {
      width: 100%;
    }
    .auth-innerright {
      display: inherit;
    }
  }

  //default dashboard
  .map-chart {
    .chart-container {
      height: 300px;
    }
  }
  .candidcahrt {
    height: 250px;
  }

  //chart widget
  .bar-chart-widget {
    .earning-details {
      i {
        right: 0;
      }
    }
  }
  .serial-chart .chart-container {
    height: 300px;
  }
  .bottom-content {
    padding: 10px;
    p {
      font-size: 12px;
    }
  }

  // from wizard two
  .stepwizard {
    .stepwizard-row {
      &:before {
        top: 17px;
      }
    }
  }
  // icons
  .icon-hover-bottom {
    .icon-popup {
      div {
        .flag-icon {
          width: 30px;
          height: auto;
        }
      }
      .form-group {
        input {
          min-width: 40%;
        }
      }
    }
  }

  //footer
  .footer-links {
    text-align: center;
  }
  .footer-copyright {
    text-align: center !important;
  }
  .jvector-map-height {
    height: 150px;
  }
  .sm-left-text {
    text-align: left !important;
  }

  // email app
  .email-wrap {
    .email-right-aside {
      .email-content {
        .email-top {
          .d-flex {
            padding-bottom: 0;
          }
          .float-end {
            float: left !important;
          }
        }
        .email-wrapper {
          .attachment {           
            ul {
              li {
                &:last-child {
                  padding-right: 0;
                }
                img {
                  width:90px;
                  height:90px;
                }
              }
            }
          }
        }
      }     
      .email-body {
        .inbox .media{
          // padding-top:15px;
          // padding-bottom:15px;
        }
      }
    }
  }
  .email-wrap {
    .email-right-aside {
      .email-profile {
        overflow-x:auto;
        .inbox{       
          width:700px;  
          p{
            width: calc(100% - 200px);
            max-width: 500px;
          }
        }
      }
    }
  }




  // alert page
  .alert-dismissible {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 294px;
    }
  }
  // button group page
  .button-group-mb-sm {
    margin-bottom: 5px;
  }
  // table components page
  .card-block {
    .table-responsive {
      .table {
        tbody {
          tr {
            td {
              span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100px;
              }
            }
          }
        }
      }
      .progress-content {
        tbody {
          tr {
            td {
              span {
                text-overflow: ellipsis;
                white-space: normal;
                max-width: 50px;
              }
            }
          }
        }
      }
      .checkbox-td-width,
      .radio-first-col-width {
        tbody {
          tr {
            td {
              min-width: 200px !important;
            }
          }
        }
      }
    }
  }
  // jsgrid-table page
  #batchDelete {
    .jsgrid-grid-header {
      .jsgrid-table {
        .jsgrid-header-row {
          .jsgrid-header-cell {
            &:first-child {
              width: 119px !important;
            }
          }
        }
      }
    }
    .jsgrid-grid-body {
      .jsgrid-table {
        .jsgrid-row {
          .jsgrid-cell {
            &:first-child {
              width: 119px !important;
            }
          }
        }
      }
    }
  }
  .jsgrid {
    .jsgrid-pager-container {
      text-align: center;
    }
  }
  // chat page
  .chat-box {
    .chat-menu {
      .nav-tabs {
        .nav-item {
          width: 32.33%;
        }
      }
      .people-list {
        ul.list {
          max-height: 433px;
          margin-bottom: 15px;
        }
      }
      #info-profile,
      #info-contact {
        margin-bottom: 15px;
      }
    }

    .chat-history {
      .call-content {
        > div {
          padding-top: 40px;
          height: auto;
        }
      }
    }
  }
  .call-chat-sidebar {
    max-width:100%;
    width:100%;
    flex-basis:auto;
  }

  // user-profile page
  .hovercard {
    .info {
      .follow {
        .text-md-end {
          text-align: center;
        }
      }
    }
  }
  .profile-img-style {
    .like-comment-sm-mb {
      margin-bottom: 20px;
    }
  }
  // forget-password page
  .reset-password-box {
    .theme-form {
      .form-group {
        .btn {
          margin-top: 15px !important;
        }
      }
    }
  }
  // gallery page
  .lg-outer {
    .lg-actions .lg-next,
    .lg-actions .lg-prev {
      top: 48%;
    }
  }
  // product page
  .feature-products {
    .filter-toggle {
      padding: 8px 15px;
      margin-left: 10px;
      border-radius: 5px;
      background-color: rgba($primary-color, 0.05);
      color: $primary-color;
    }    
    span{
      font-size: 12px;
      &.f-w-600{
        padding-top:8px;
        display:inline-block;       
      }
    }    
  } 
  .grid-options {
    ul {
      li {
        display: none;
      }
    }
  }
  .product-wrapper {
    &.sidebaron {
      .product-grid {
        .product-wrapper-grid {
          margin-left:0;
        }
      }     
    }
  }
  // .product-wrapper-grid {
  //   &.list-view {
  //     .product-box {
  //       .product-img {
  //         width: 42%;
  //       }
  //     }
  //   }
  // }
  .product-filter {
    .product-box {
      flex-wrap: nowrap;
      .product-img {
        width: 100px;
      }
      .product-details {
        padding: 0 !important;
      }
    }
  }
   
}
@media screen and (max-width: 700px) {
  .bg-gallery {
    min-height: 470px;
  }
}
@media screen and (max-width: 650px) {
  .bg-gallery {
    height: 63vh;
  }
}
@media screen and (max-width: 600px) {
  .bg-gallery {
    height: 60vh;
  }
  .user-profile{
    .photos{
      ul {
        li{
          width:53px;
        }
      }
    }
  }


}
@media screen and (max-width: 540px) {
  .bg-gallery {
    height: 57vh;
  }
}
@media screen and (max-width: 575px) {
  .search-form {
    .search-bg {
      > i {
        display: none !important;
      }
    }
  }
  .job-accordion {
    .card {
      box-shadow: 0 0 11px 5px $common-shadow-color !important;
    }
  }
  .alert-dismissible {
    p {
      max-width: 209px;
    }
  }
  .customizer-links {
    display: none;
  }
  .m-r-30 {
    margin-right: 15px;
  }


  // card css //
  .card {
    .card-footer {
      padding: 15px;
    }
  }
  .setting-list {
    top: 15px;
    .setting-option {
      height: 21px;
      li {
        &:first-child {
          i {
            font-size: 12px;
          }
        }
      }
    }
  }
  .setting-primary,
  .setting-secondary,
  .setting-white {
    width: 22px;
    height: 22px;
  }

  // product page 
  .feature-products{
    .text-end{      
      text-align: left !important;
    }
    .select-options{
      float:left;
      margin-right: 10px;
    }
  }
  .call-chat-body {
    .chat-box{
      height:auto;
    }
  }
  .chat-box{
    .chat-menu{
      .people-list{
        ul.list{
          max-height:400px;
        }
      }
    }
    .chat-history {
      .call-content{
        min-height:447px;
      }
    }
  }

  // user profile
  .user-profile{
    .photos{
      ul{
        li{
          width:18%;
        }
      }
    }
    .profile-post{
      padding:20px;      
      .post-body {
        figure{
          margin-bottom:20px;
        }
        .post-react {
          ul {
            li {             
              img{
                width:20px;
                height:20px;
              }
              &:nth-child(n+2){
                margin-left:-15px;
              }
            }
          }
          h6{
            margin-left:10px;
            font-size:13px;
          }
        }
      }
    }
    .profile-header{
      padding:0;
      height:auto;
      .profile-img-wrrap{
        display:block;
        height:280px;
      }       
      .userpro-box{
        max-width:100%;
      }
    }
  }

   

  

  //dashboard mian //
  .center-content{
    margin-top:5px;
    p {
      text-align: left;
      i {
        margin-left: 0 !important;
      }
    }
  }
  .dashboard-default-sec,
  .dashboard-2-main {
    .card {
      &.income-card {
        .card-body {
          .round-box {
            margin-bottom: 10px;
            width: 45px;
            height: 45px;
            svg {
              height: 18px;
            }
          }
          .knob-block {
            canvas {
              width: 80px;
              height: 80px;
            }
          }
          h5 {
            font-size: 18px;
            margin-bottom: 5px;
          }
          p {
            margin-bottom: 10px;
          }
        }
      }
      .card-header {
        i {
          font-size: 12px;
        }
      }
    }
  }
  .dashboard-default-sec {
    #chart-dashbord {
      .apexcharts-legend {
        &.position-right,
        &.position-left {
          top: 28px !important;
        }
      }
    }
  }
  .profile-greeting {
    h3 {
      font-size: 22px;
      margin-bottom: 5px;
    }
    p {
      margin-bottom: 10px;
      line-height: 1.4;
      padding: unset;
    }
  }
  .trasaction-sec {
    .transaction-totalbal {
      h2 {
        font-size: 16px;
      }
    }
  }

  // support-ticket
  .support-ticket {
    .pro-gress {
      .total-num {
        font-size: 18px;
      }
    }
  }

  // main header css
  .page-main-header {
    .main-header-right {
      .left-menu-header {
        ul {
          li {
            .search-form {
              // .search-bg {
              //   display: none;
              // }
              .form-control-plaintext {
                top: 44px;
                position: absolute;
                transition: all linear 0.3s;
                left: -15px;
                background-color: $white;
                transform: translateY(-35px) scaleY(0);
                opacity: 0;
                border-radius: 0;
                visibility: hidden;
                width: 180px !important;
                padding: 10px 10px 10px 15px;
                &.open {
                  transform: translateY(0px) scaleY(1);
                  opacity: 1;
                  visibility: visible;
                  transition: all linear 0.3s;
                  z-index: 2;
                }
              }
            }
          }
        }
      }
      .nav-right {
        .btn-primary-light {
          padding: 6px 15px;
          svg {
            margin-right: 6px;
          }
        }
        .bookmark-dropdown {
          right: -168px;
          width: 270px;
        }
      }
    }
  }
  .page-wrapper {
    .page-main-header {
      .main-header-right {
        .left-menu-header {
          .search-form {
            > .fa {
              display: none;
            }
          }
        }
        > .mobile-toggle {
          padding-right: 15px;
        }
        .nav-right {
          > ul {
            top: 55px;
            .search-form {
              i {
                display: none;
              }
            }
          }
          &.right-menu {
            ul {
              justify-content: flex-end;
              li {
                &.px-0 {
                  padding: 6px !important;
                }
              }
            }
          }
          > ul {
            li {
              margin-right: 10px;
              &.onhover-dropdown {
                ul {
                  li {
                    margin-right: 0 !important;
                  }
                }
              }
            }
          }
          .notification-dropdown {
            left: -93px !important;
            padding: 0;
            width: 270px !important;
            &.onhover-show-div {
              &:before,
              &:after {
                left: 102px !important;
                right: unset !important;
              }
            }
          }
          .chat-dropdown {
            left: -132px;
            width: 270px;
            padding: 0;
            &:after,
            &:before {
              left: 138px !important;
            }
          }
        }
        .main-header-left {
          padding: 15px;
          .toggle-sidebar {
            margin-left: 20px;
          }
        }
      }
    }
    .page-body-wrapper {
      .page-header {
        .btn-group {
          .btn {
            padding: 8px 30px;
          }
        }
      }
    }
  }
  .pagination-lg {
    .page-link {
      font-size: 1rem;
    }
  }

  // book mark //
  .bookmark {
    text-align: left;
    margin-top: 10px;
  }

  // main sidebar css
  .page-wrapper{
    &.compact-wrapper{
      .page-body-wrapper {
        &.sidebar-icon {
          header {
            &.main-nav {
              top: 59px;
              .main-navbar {
                .nav-menu {
                  height: calc(100vh - 79px);
                }
              }
            }
          }
        }
      }
    }
  }

  // footer
  footer {
    p {
      font-size: 12px;
    }
  }

  // general widget page css
  .mobile-clock-widget {
    #date {
      margin-top: 15px;
    }
  }
  .social-widget-card {
    .b-b-light {
      padding: 15px;
      margin-bottom: 15px;
    }
  }
   .cal-date-widget {
    .cal-datepicker {
      margin-top: 15px;
    }
  }
  .static-top-widget {
    .media-body {
      .icon-bg {
        width: 70px;
        height: 70px;
      }
    }
  }
  .animat-md-center {
    text-align: center;
  }

  // Add post page css
  .add-post {
    form {
      .m-checkbox-inline {
        label {
          margin-right: 15px;
        }
      }
      .form-group {
        margin-bottom: 15px;
      }
    }
    .dropzone {
      margin-bottom: 15px;
    }
    .btn-showcase {
      text-align: center;
      .btn {
        margin-right: 10px;
        padding: 5px 13px;
      }
    }
  }

  // internationalization page css
  .main {
    .langChoice {
      right: 15px;
      padding: 4px 7px;
    }
  }
  .language-xs {
    margin-top: 15px;
  }

  // product page //
  .product-color {
    li {
      width: 15px;
      height: 15px;
    }
  }
  ul.pro-services {
    li {
      h5 {
        font-size: 14px;
      }
      p {
        font-size: 13px;
      }
    }
  }

  // tab //
  .tabs-scoial {
    &.border-tab {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            height: auto;
          }
        }
      }
    }
  }

  // avatar page css
  .customers {
    &.avatar-group {
      margin-right: 15px;
    }
  }
  .vertical-mobile-sidebar {
    top: 19px;
  }

  // internationalization page //
  .hdg_main {
    font-size: 16px;
  }

  //notify index page
  .alert-theme {
    i {
      margin-right: 10px !important;
    }
    button {
      top: 2px !important;
    }
  }
  .chart-widgets-small {
    .chart-container {
      height: unset !important;
    }
  }
  .bar-chart-widget {
    .earning-details {
      i {
        right: -30px;
      }
    }
  }
  .widget-joins {
    &:before {
      display: none;
    }
    .ps-0 {
      padding-left: 15px !important;
    }
    .border-after-xs {
      &:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 94%;
        background-color: $light-semi-gray;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
  .clockpicker-align-top {
    left: 45px !important;
    top: 406px !important;
  }
  .gallery {
    margin-bottom: -15px;
  }
  .my-gallery {
    figure {
      margin-bottom: 15px;
      &:nth-child(9),
      &:nth-child(10) {
        margin-bottom: 15px;
      }
    }
    &.gallery-with-description {
      figure {
        &:last-child {
          margin-bottom: 20px;
        }
      }
    }
  }
  #aniimated-thumbnials {
    figure {
      &:nth-child(12),
      &:nth-child(11),
      &:nth-child(10),
      &:nth-child(9) {
        margin-bottom: 15px;
      }
    }
  }
  .photoswipe-pb-responsive {
    padding-bottom: 30px !important;
  }
  // social app page css
  .avatar-showcase {
    .pepole-knows {
      text-align: center;
      ul {
        li {
          margin-right: 14px;
        }
      }
    }
  }
  .social-app-profile {
    .hovercard {
      .user-image {
        .avatar {
          margin-top: -56px;
          img {
            width: 100px;
            height: 100px;
          }
        }
        .icon-wrapper {
          left: 52%;
          top: 10px;
          height: 30px;
          width: 30px;
          font-size: 15px;
          &:hover {
            font-size: 12px;
          }
        }
        .share-icons {
          right: 15px;
          li {
            margin-right: 0px;
          }
        }
      }
      .info {
        .tabs-scoial {
          .user-designation {
            border-top: none;
            border-bottom: none;
            margin-bottom: 0;
            padding: 0px 0px;
          }
        }        
      }
    }    
  }
  .socialprofile {
    .social-btngroup {
      margin: 15px 0;
    }
    .social-group {
      margin-top: 15px;
    }
  }
  .social-status {
    form {
      .form-group {
        .form-control-social {
          margin-bottom: 15px;
        }
      }
    }
    .media {
      margin-bottom: 15px;
    }
  }
  .new-users-social {
    margin-bottom: 15px;
  }
  .tabs-scoial {
    position: relative;
    padding-top: 90px;
    li{
      &:nth-child(3){
        position: absolute;
        top: 25px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    &.border-tab {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            padding: 10px 18px;
          }
        }
      }
    }
  }
  .timeline-content {
    p,
    .comments-box {
      margin-top: 15px;
    }
    .comments-box {
      .media {
        .m-r-20 {
          margin-right: 10px;
        }
        .media-body {
          .input-group {
            .input-group-append {
              .btn {
                padding: 6px;
              }
            }
          }
        }
      }
    }
    .comment-number {
      i {
        margin-right: 15px;
      }
    }
  }
  .social-chat {
    margin-top: 15px;
    .media-body {
      padding: 15px;
    }
    .your-msg,
    .other-msg {
      margin-bottom: 15px;
    }
  } 
  .social-network{
    span {
      margin-bottom: 15px;
    }
  }
  .social-list {
    .media {
      margin-bottom: 15px;
    }
  }
  .details-about + .details-about {
    margin-top: 15px;
  }
  .your-details-xs {
    margin-top: 15px;
  }
  .social-header {
    h5 {
      span.pull-right {
        float: right !important;
        margin-top: 0 !important;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .activity-log {
    .my-activity {
      + .my-activity {
        margin-top: 15px;
      }
    }
  }
  .photos {
    ul {
      li{
        width:70px;
      }
    }
  }  
  .edit-profile {
    .card {
      .card-header {
        h4 {
          font-size: 18px;
        }
      }
    }
    .profile-title {
      .media {        
        .media-body {
          h3 {
            font-size: 16px;
          }
        }
      }
    }
  }  


  // timeline small page css
  .timeline-small {
    .media {
      margin-bottom: 15px;
      .media-body {
        h6 {
          margin-bottom: 15px;
        }
      }
      .timeline-round {
        &.timeline-line-1 {
          &:after {
            bottom: -39px;
            height: 30px;
          }
        }
        &.small-line {
          &:after {
            bottom: -17px;
            height: 8px;
          }
        }
        &.medium-line {
          &:after {
            bottom: -30px;
            height: 22px;
          }
        }
      }
    }
  }
  #cd-timeline {
    margin-right: unset;
  }

  // touchspin //
  .bootstrap-touchspin {
    .input-group {
      .btn {
        padding: 5px 15px;
      }
    }
  }

  // landing page css start
  .and-many-more {
    font-size: 52px;
  }
  .page-wrapper {
    .section-space {
      padding: 40px 0;
    }
  }
  .landing_gallery_section_img {
    margin-bottom: -40px;
  }
  .bg-sky-blue {
    .main-landing {
      height: 480px;
      .position-cards {
        display: none;
      }
    }
  }
  .landing-main {
    h4 {
      font-size: 17px;
    }
    .main-landing {
      padding: 0px 0px 0px 0px;
    }
    .footer-bg {
      h2 {
        font-size: 21px;
      }
      button {
        font-size: 18px;
      }
    }
  }
  // landing page css ends

  // helper classes page start
  .helper-classes {
    padding: 15px;
  }

  // helper classes page ends
  .form-builder-2-header {
    ul {
      li {
        text-align: left;
      }
    }
    .form-inline {
      display: block;
      width: 100%;
      .form-group {
        width: 100%;
        margin-right: 0;
      }
      button {
        width: 100%;
        text-align: left;
      }
    }
  }
  .page-builder {
    .btn-grid {
      margin-bottom: 7px;
      padding-right: 5px;
    }
    .ge-canvas {
      &.ge-editing {
        .row {
          padding: 15px;
        }
        .ge-tools-drawer {
          margin: 10px;
        }
      }
    }
  }

  .wizard-4 {
    .step-container {
      div {
        &.content {
          margin-top: 10px;
        }
      }
    }
  }
  .nav-tabs {
    .nav-item {
      &.show {
        .nav-link {
          border-top: 0;
          border-bottom: 0;
        }
      }
    }
    .nav-link {
      border-top: 0;
      border-bottom: 0;
      &:hover,
      &.active,
      &:focus {
        border-top: 0;
        border-bottom: 0;
      }
    }
  }

  .border-tab {
    &.nav-tabs {
      .nav-item {
        width: 100% !important;
      }
    }
    .weather-widget-two {
      .bottom-whetherinfo {
        .whether-content {
          top: 35px;
        }
      }
    }
    .social-widget-card {
      .b-b-light {
        padding: 15px;
        margin-bottom: 15px;
      }
    }
    .ecommerce-widget {
      .progress-showcase {
        margin-top: 15px;
      }
    }
  }
  .nav {
    display: inherit;
    text-align: center;
    border-bottom: none;
  }
  // tab material start
  .border-tab.nav-left {
    .nav-link {
      text-align: center;
      border-left: none;
    }
  }
  .border-tab.nav-right {
    .nav-link {
      text-align: center;
      border-right: none;
    }
  }
  .border-tab.nav-tabs {
    margin-bottom: 10px;
  }
  .tab-content {
    ~ .nav-tabs {
      &.border-tab {
        margin-top: 10px;
      }
    }
  }
  // tab material end
  .nav-pills {
    text-align: center;
  }
  .crm-overall {
    margin: 0 -15px -15px;
  }
  .product-page-main {
    padding: 15px;
    .m-t-15 {
      margin-top: 0 !important;
      .btn {
        margin-top: 10px;
      }
    }
    .pro-group {
      .pro-shop {
        button {
          &:last-child {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .tabbed-card {
    ul {
      &.border-tab {
        &.nav-tabs {
          .nav-item {
            .nav-link {
              padding: 5px 15px 10px;
            }
          }
        }
      }
      padding: 10px 0 5px 0;
      position: relative;
      width: 100%;
      left: 0;
    }
  }
  .todo {
    .action-box {
      &.large {
        height: 25px;
        width: 25px;
        .icon {
          font-size: 14px;
          vertical-align: -3px;
        }
      }
    }
    .todo-list-wrapper {
      #todo-list {
        li {
          .task-container {
            .task-label {
              font-size: 14px;
            }
          }
        }
      }
      .mark-all-tasks {
        top: 15px;
        right: 15px;
      }
    }
  }
  .datetime-picker {
    label {
      text-align: left !important;
    }
  }
  .redial-chart-block {
    text-align: center;
  }
  #nav-tabContent {
    margin-top: 15px;
  }
  .reset-password-box {
    width: 430px;
  }
  .auth-bg {
    padding: 25px 15px;
  }
  .auth-bg-effect {
    display: none;
  }
  .date-picker {
    .text-end {
      text-align: left !important;
    }
  }
  .dataTables_wrapper {
    table {
      &.dataTable {
        margin-bottom: 15px !important;
      }
    }
  }
  ul {
    &.pagination {
      li {
        &:before {
          line-height: 3;
        }
      }
    }
  }
  div {
    &.table-responsive {
      > div {
        &.dataTables_wrapper {
          > div {
            &.row {
              > div {
                &[class^="col-"] {
                  &:last-child {
                    padding-left: 0 !important;
                  }
                  &:first-child {
                    padding-right: 0 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.dataTables_wrapper {
      div {
        &.dataTables_length {
          margin-bottom: 15px;
          select {
            min-height: 30px;
          }
        }
      }
      table {
        &.dataTable {
          th,
          td {
            padding: 0.5rem;
          }
        }
      }
      div {
        &.dataTables_paginate {
          margin-top: 15px !important;
          .paginate_button {
            padding: 1px 7px;
            &.previous {
              font-size: 0;
              padding: 0;
              &:before {
                content: "\e64a";
                font-family: $font-themify;
                font-size: 11px;
                padding: 0 5px;
              }
            }
            &.next {
              font-size: 0;
              padding: 0;
              &:before {
                content: "\e649";
                font-family: $font-themify;
                font-size: 11px;
                padding: 0 5px;
              }
            }
          }
        }
      }
      .dataTables_filter {
        margin-bottom: 15px;
        input {
          &[type="search"] {
            height: 30px;
          }
        }
      }
    }
  }  
  .jvector-map-height {
    height: 250px;
  }
  .icon-hover-bottom {
    .form-group {
      display: flex;
    }
    .icon-popup {
      padding: 15px;
    }
    svg {
      &.climacon {
        height: 50px;
        width: 50px;
      }
    }
  }
  .blog-box {
    &.blog-shadow {
      &:before {
        box-shadow: inset 0px -100px 100px -13px rgba(0, 0, 0, 0.6);
      }
    }
    .blog-details {
      p,
      .single-blog-content-top {
        margin-top: 15px;
      }
      h4 {
        font-size: 16px;
        margin-top: 10px;
      }
      .blog-social {
        li {
          font-size:12px;
          font-weight:500;          
          &:nth-child(n+2){
            padding-left:unset;
            margin-left:10px ;
            border-left:none;
          }
        }
      }
    }
    
  }
  .comment-box {
    h4 {
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
    ul {
      ul {
        margin-left: 30px;
      }
    }
    li {
      margin-bottom: 15px;
      .media {
        display: block;
        img {
          height: 50px;
          width: 50px;
          padding: 3px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .card {
    .card-header {
      .card-header-right {
        top: 6px;
      }
    }
  }
  .ace-editor {
    height: 320px;
  }
  .gallery {
    > a {
      margin-bottom: 15px;
      text-align: center;
    }
  }
  .card {
    .card-header {
      h5 {
        font-size: 17px;
      }
    }
  }
  .theme-form {
    .form-group {
      margin-bottom: 15px;
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .default-according {
        .card {
          .card-header,
          .card-body,
          .card-footer {
            padding: 1rem 1.25rem;
          }
        }
      }
      .card {
        box-shadow: 0 0 11px 5px $common-shadow-color;
        margin-bottom: 15px;
        overflow: hidden;
        .card-header,
        .card-body {
          // padding: 15px;
          .tab-content {
            .m-t-30 {
              margin-top: 15px !important;
            }
            .m-b-30 {
              margin-bottom: 15px !important;
            }
          }
        }
      }
      .page-body {
        padding:5px 0 !important;
        padding-top:20px!important;
      }
      .page-header {
        // padding-top: 20px;
        padding-bottom: 20px;
        .row {
          h3 {
            font-size: 20px;
          }
        }
      }
    }
    .search-form {
      .form-group {
        margin-right: 0;
      }
    }
  }
  /* alert responsive css start here */
  $alert-name: primary, secondary, success, danger, warning, info, light, dark;
  $alert-color: $primary-color, $secondary-color, $success-color, $danger-color,
    $warning-color, $light-font, $light-gray, $dark-color;

  @each $var in $alert-name {
    $i: index($alert-name, $var);
    .alert-#{$var}.inverse {
      background-color: transparent;
    }
  }
  .alert {
    .close {
      height: 100%;
    }
  }
  //business card
  .calender-widget {
    .cal-desc {
      padding: 10px 15px 15px !important;
      p {
        max-height: 100%;
      }
    }
  }

  //user card
  .custom-card {
    .card-footer {
      > div {
        h6 {
          font-weight: 600;
        }
      }
    }
    .card-profile {
      margin-top: 25px;
    }
    // .card-header {
    //   img {
    //     margin-top: -195px;
    //   }
    // }

    .card-social {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  //calendar
  .calendar-wrap {
    .fc-toolbar {
      .fc-center {
        margin-top: 10px;
      }
    }
    a.fc-more {
      font-size: 0.7em;
    }
  }

  // megaoption page responsive scss
  .mega-inline {
    display: block;
  }
  .megaoptions-border-space-sm {
    padding-bottom: 30px !important;
  }
  //blog
  .blog-box.blog-shadow {
    .blog-details {
      padding: 15px;
      p {
        margin-bottom: 0;
      }
      h4 {
        margin-bottom: 10px;
      }
    }
  }
  .blog-box { 
    .blog-details {
      padding: 20px;
    }
    .blog-date {
      span {
        font-size: 25px;
      }
    }
  }
  //error and maintenace
  .error-wrapper {
    padding: 20px 0;
    .btn {
      margin-top: 15px;
    }
    .img-100 {
      width: 50px !important;
    }
    .error-heading {
      margin-top: 20px;
      .cloud-second {
        margin-top: -60px;
      }
      .headline {
        font-size: 150px;
      }
    }
    .maintenance-heading {
      .cloud-second {
        display: none;
      }
      .headline {
        font-size:30px;
        margin-top:-10%;
        letter-spacing:2px;
      }
    }
  }

  //coming soon
  .comingsoon {
    .comingsoon-inner {
      .coming-soon-bottom-link {
        margin-top: 20px;
        line-height: 1;
      }
      h5 {
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 18px;
      }     
      .countdown {
        padding: 20px 0px;
        ul li {
          margin: 0 7px;
        }
        .title {
          font-size: 12px;
        }
        .time {
          width: 45px;
          height: 45px;
          line-height: 45px;
          font-size: 16px;
          margin: 0 auto;
        }
      }
      img{
        width:auto;
        height:75px;
      }
    }
  }
 

  // Form Wizard One start
  .form-wizard {
    .btn-mb {
      margin-bottom: 15px;
    }
  }
  // Form Wizard One ends

  // Form Wizard Two Start
  .stepwizard {
    margin-bottom: 15px;
  }
  // Form Wizard Two Ends

  // Form Wizard Three Start
  .f1 {
    .f1-steps {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
  //Form Wizard Three ends

  // form wizard--4 scss
  .wizard-4 {
    ul.anchor {
      width: 100%;
      padding-bottom: 15px;
      padding-right: 0;
    }
    .step-container {
      width: 100%;
    }
  }
  //chart
  .flot-chart-container {
    height: 300px;
  }
  .small-chart-widget {
    .chart-container {
      height: unset !important;
    }
  }
  .status-widget {
    .text-sm-end {
      text-align: right;
    }
  }
  //mega menu
  .mega-menu {
    padding-left: 15px;
    .onhover-show-div {
      height: 405px;
      left: 0;
      top: 61px;
      padding: 15px;
    }
    .lg-mt {
      margin-top: 10px;
    }
    .xs-mt {
      margin-top: 15px;
    }
  }
  // navs page
  .nav-md-mt {
    margin-top: 15px;
  }
  .navs-icon {
    padding: 15px;
    .main-section {
      padding-top: 15px;
    }
    .separator {
      margin: 15px 0;
    }
  }
  .nav-list {
    padding: 15px;
  }
  .navs-dropdown {
    button {
      margin-top: 15px;
    }
    .onhover-show-div {
      top: 64px;
    }
  }
  .lg-mt {
    margin-top: 15px;
  }
  // scroll reval
  #aniimated-thumbnials {
    a {
      &:last-child {
        img {
          margin-bottom: 15px;
        }
      }
    }
  }
  // tilt
  .tilt-showcase {
    .mt-4 {
      margin-top: 15px !important;
    }
    ol {
      .m-b-20 {
        margin-bottom: 15px !important;
      }
    }
  }

  // state color page
  .xs-mt {
    margin-top: 15px;
  }
  // tag-pills page
  span:last-child.tag-pills-sm-mb {
    margin-top: 3px;
  }
  // alert page

  // animated modal page
  .animated-modal {
    .form-group {
      .form-control {
        width: 50%;
        margin: 0 auto;
      }
    }
    .animated-modal-md-mb {
      margin-bottom: 15px !important;
    }
  }
  // steps page
  .steps-md-mt {
    margin-top: 15px;
  }
  .steps-sizing-sm-mb {
    margin-bottom: 15px;
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 96% !important;
      }
    }
  }
  // datatable API page
  #API-chield-row_wrapper {
    #API-chield-row {
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 20px;
            }
          }
        }
      }
    }
  }
  // chart-flot page
  .chart-block {
    .flot-chart-container {
      p#choices {
        width: 100%;
      }
      #toggling-series-flot {
        width: 100% !important;
      }
    }
  }
  // summer-note page
  .click2edit {
    & ~ .note-editor {
      &.note-frame {
        margin-bottom: 15px;
      }
    }
  }
  // chat page
  .chat-box {
    .chat-left-aside {
      .people-list {
        height: auto;
      }
    }
  }
  .call-chat-sidebar {
    max-width: 100%;
    width: 100%;
    flex-basis: auto;
    .people-list {
      height: auto;
    }
  }
  .chat-left-aside {
    .people-list {
      ul {
        max-height: 300px;
      }
    }
  }
  // support ticket page
  .ecommerce-widget {
    .progress-showcase {
      margin-top: 15px;
    }
  }
  // forget-password page
  .reset-password-box {
    .theme-form {
      .form-group {
        .btn {
          margin-top: 13px !important;
        }
      }
    }
  }
  // gallery-with-description page
  .gallery-with-description {
    a {
      > div {
        margin-bottom: 15px;
      }
    }
  }
  // gallery hover page
  .gallery-img-mb-sm {
    margin-bottom: 15px;
  }
  .page-wrapper {
    &.document {
      .landing-main {
        .navbar-expand-lg {
          .navbar-brand {
            width: 150px;
            img {
              width: unset;
            }
          }
        }
      }
      .page-body-wrapper {
        &.document-content {
          .page-sidebar {
            .nav-pills {
              text-align: left;
              .nav {
                text-align: left;
              }
            }
          }
          .page-body {
            margin-top: 75px !important;
          }
        }
      }
    }
  }
  // peity chart page
  .xm-mb-peity {
    margin-bottom: 8px;
  }
  // login page
  .authentication-main {
    padding: 30px 15px;
  }
}
@media screen and (max-width: 480px) { 
.editor-toolbar a{
  width: 16px;
  &:before{
    line-height: 30px;
    font-size: 12px;
  }
}
.note-editor{
  .btn-group .btn {   
    padding: 0.375rem 0.5rem;
  }
}
.CodeMirror pre{
  font-size: 12px;
}
  .and-many-more {
    font-size: 45px;
  }
  .viho-demo-section {
    .viho-demo-content,
    .gallery_content {
      p {
        font-size: 13px;
      }
    }
  }
  .and-more {
    font-size: 20px;
  }
  .gallery-img-blur-div {
    height: 70px;
  }
  .bg-gallery {
    min-height: 390px;
  }
  .landing_gallery_section_img {
    margin-bottom: -80px;
    &:before {
      height: 350px;
    }
  }
  .box-layout {
    &.page-wrapper {
      .set-col-6,
      .set-col-3,
      .set-col-7,
      .set-col-5 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .bg-sky-blue {
    .main-landing {
      .landing-main-content {
        h3 {
          font-size: 26px;
        }
      }
    }
  }
  .page-wrapper {
    .page-main-header {
      .main-header-left {
        .logo-wrapper {
          img {
            height: 25px;
          }
        }
      }
      // .page-wrapper .page-main-header .main-header-right .nav-right > ul
      .main-header-right {
        .nav-right {         
          > ul{
            padding-left: 15px;
            padding-right: 15px;
          }          
          .btn-primary-light {
            padding:5px 7px;
            font-size:12px;
            svg{
              width:14px;
            }
          }
          > ul {
            height: 50px;
            li {
              margin-right: 5px;
              svg {
                width: 16px;
              }
            }
          }
        }
      }
    }
  }
  .todo{
    .btn{     
      padding: 7px 20px;
    }
  }

// chat 
.chat-box {
  .people-list {   
    ul {
      li{
        display: block;              
      }
    }
  }
}

  //landing page css
  .wizard-4 {
    .action-bar {
      .btn {
        width: 100%;
        & + .btn {
          margin-top: 10px;
        }
      }
    }
  }
  ul {
    &.notification-dropdown {
      &.onhover-show-div {
        width: 260px;
        right: -80px;
        &:before,
        &:after {
          right: 89px !important;
        }
      }
    }
  }
  .inline-block-sm {
    display: inline-block;
  }
  .pagination-lg {
    .page-link {
      padding: 0.3rem 0.65rem;
    }
  }
  .nav-pills {
    li {
      width: 100%;
    }
  }
  .reset-password-box {
    width: 290px;
  }
  .icon-hover-bottom {
    .icon-first {
      margin-right: 10px;
    }
    .icon-popup {
      .icon-class {
        display: none;
      }
    }
  }
  .error-wrapper {
    .maintenance-heading {
      margin-top: 0;
    }
  }
  .card {
    .card-header {
      > span {
        & + span {
          display: none;
        }
      }
    }
  }
  .custom-card {
    padding: 0;
    .card-header {
      img {
        // margin-top: -100px;
      }
    }
    .profile-details {
      h6 {
        margin-bottom: 15px;
      }
      h4 {
        font-size: 20px;
      }
    }
    .card-profile {
      margin-top: unset;  
    }
  }
  .card {
    .card-header {
      .card-header-right {
        i {
          margin: 0 3px;
          font-size: 14px;
        }
      }
    }
  }
  .knob-block {
    .chart-clock-main {
      .clock-small {
        left: 34px;
        top: 74px;
      }
    }
  }
  .knob-block {
    .chart-clock-main {
      margin: auto;
      width: auto;
      .clock-medium {
        position: absolute;
        left: 33px;
        top: 33px;
        canvas {
          width: 180px !important;
          height: 180px !important;
        }
      }
      .clock-small {
        left: 34px;
        top: 74px;
      }
      .clock-large {
        left: 0;
        top: 0;
        margin: 0 auto;

        canvas {
          width: 245px !important;
          height: 245px !important;
        }
      }
    }
  }

  //blog
  .blog-box.blog-shadow {
    .blog-details {
      padding: 10px;
      h4 {
        font-size: 14px;
      }
    }
  }
  .cal-date-widget {
    padding: 30px;
  }
  .calender-widget {
    .cal-img {
      height: 225px;
    }
  }
  .company-card {
    .details {
      .media {
        flex-wrap: wrap;
      }
    }
  }

  // from wizard two
  .stepwizard {
    .stepwizard-row {
      &:before {
        top: 17px;
      }
      .stepwizard-step {
        .btn {
          padding: 6px 22px;
        }
      }
    }
  }

  // product page
  .product-sidebar {
    transform: translateX(-300px) scaleX(0);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    height: 0;
    position: absolute;
    z-index: 9;
    width: 300px;
    &.open {
      transform: translateX(0px) scaleX(1);
      box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
      visibility: visible;
      opacity: 1;
      height: auto;
      top: -45px;    
    }
    .filter-section {
      .card-header {
        display: none;
      }
       .left-filter{
         top:unset !important;
       }
    }
  }
  .feature-products {
    .filter-toggle{
      font-size:14px;
      padding:7px 15px;
      margin-left: 10px;
      border-radius: 5px;
      background-color: rgba($primary-color, 0.05);
      color: $primary-color;
      h6{
        font-size:12px;
      }
    }
    .text-end{
      text-align:left !important;
    }
  }
  .d-none-productlist{
    display: block;
    margin-right: 10px;
  }


  // email app
  .email-wrap {
    .email-right-aside {
      .email-content {
        .email-wrapper {
          .attachment {
            ul {
              li {
                img {
                  width: 83px;
                  height: 83px;
                }
              }
            }
          }
        }
      }
    }
    .actions {
      margin-bottom:-10px;
      li {
        margin-bottom:10px;
        .btn{
          padding:5px 10px;
          font-size: 12px;
        }        
      }
    }
    .email-wrapper {
      .right-download{
        float:none;
        margin-top:5px;
      }
      h6{
        float:none;
      }
    }
  }

  //social app 
  .social-app-profile{
    .hovercard{
      .user-image{
        .share-icons {
          li{
            .social-icon{
              width:30px;
              height:30px;
            }
          }
        }
        .icon-wrapper{
          top:-5px;
        }
        .avatar{
          img{
            width:80px;
            height:80px;            
          }
        }
      }
    }
  }
 
  //mega menu
  .mega-menu {
    padding-left: 20px;
    padding-top: 3px;
    .onhover-show-div {
      height: 405px;
      left: 0;
      top: 61px;
      padding: 15px;
    }
    .lg-mt {
      margin-top: 10px;
    }
    .xs-mt {
      margin-top: 15px;
    }
  }
  //  tag-pills
  .badge {
    padding: 0.42em 0.7em;
  }
  // alert page
  .alert-dismissible {
    .close {
      top: -1px;
      span {
        font-size: 19px;
      }
    }
  }
  // bootstrap notify page
  .notify-alert {
    width: 90%;
  }
  // invoice template page
  .invoice {
    .text-md-end {
      margin-top: 10px;
    }
  }
  // ecoomerce page //
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        display: block;
        .product-img {
          width: 100%;
        }
        .product-details {
          text-align: center;
        }
      }
    }
  }

  //user profile
  .user-profile{
    .profile-header {
      .profile-img-wrrap{
        height:210px;
      }
      .userpro-box{
        padding:15px;
      }
    }
    .follow {
      .follow-num{
        font-size:16px;
      }
      span{
        font-size:12px;
      }
      ul{
        &.follow-list {
          li{
            &:nth-child(n+2){
              margin-left: 10px;
              padding-left: 10px;
            }
          }
        }
      }
    }
  }

 



}
@media screen and (max-width: 420px) {
  .and-many-more {
    font-size: 37px;
  }
  // Chat Box //
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-header {
          display: block;
          .chat-menu-icons {
            justify-content: flex-start;
          }
        }
      }
    }
    .chat-history {
      .call-icons {
        ul {
          li {
            width: 40px;
            height: 40px;
            a {
              line-height: 1;
            }
          }
        }
      }
    }
    .chat-menu{
      &.show{
        top:102px;
      }
    }
    .chat-menu{
      width:260px;
      .people-list{
        ul{
          &.list{
            height:360px;
          }
        }
      }
    }
  }
  .chat-menu{
    top:120px;
  }
  .product-page-main {
    .pro-group {
      .pro-shop {
        button {
          display: block;
          &:nth-child(n + 2) {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .product-page-main .border-tab.nav-tabs .nav-item {
    width: 100% !important;
  }
  .border-tab {
    &.nav-tabs {
      display: block;
    }
  }
  .tabs-scoial{
    &.border-tab{
      &.nav-tabs {
        .nav-item {
          width: auto !important;
          .nav-link{
            padding: 8px 8px;
            font-size: 14px;
          }
        }
      }
    }
  }


  // product page 
  .feature-products{
    span.f-w-600{
      padding-top: unset;
      margin-bottom: 5px;
    }    
  }


  //user
  .custom-card {
    .card-social {
      li {
        a{
          width: 25px;
          height: 25px;
          font-size: 12px;
        }
        &:nth-child(n+2){
          margin-left:5px;
        }
      }
    }
  }

  // emial application
  .email-wrap {
    .email-body {
      .email-compose {
        .compose-header{
          display:block;
          .btn{
            margin-top:10px;
          }
        }
      }
    }
  }

  // job search
  .job-sidebar {
    .job-left-aside{
      padding:15px;
    }
  }
  


}
@media screen and (max-width: 397px) {
  .clockpicker-align-top {
    top: 426px !important;
  }
}
@media screen and (max-width: 360px) {
  .datepicker {
    width: 239px;
  }
  .add-post {
    form {
      .m-checkbox-inline {
        label {
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .map-js-height {
    height: 250px;
  }
  .alert-theme {
    font-size: 12px;
    button {
      right: 30px !important;
    }
    i {
      display: none;
    }
    span {
      + span {
        + span {
          padding: 15px;
        }
      }
    }
  }
  .bar-chart-widget {
    .earning-details {
      i {
        right: -73px;
      }
    }
  }
  .widget-joins {
    .border-after-xs {
      &:after {
        width: 92%;
      }
    }
  }

  // chart widget page css
  .status-widget {
    svg {
      width: 18px;
      height: 18px;
    }
  }
  // user cards page css
  .custom-card {
    .card-footer {
      > div {
        h6 {
          font-size: 12px;
          h3 {
            font-size: 16px;
          }
        }
      }
    }
  }
  .main .langChoice {
    padding: 4px 7px;
    width: 30%;
    position: unset;
    margin-right: auto;
    margin-top: 15px;
  }
  // social app page css
  .tabs-scoial {
    &.border-tab {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            padding: 10px 5px;
            font-size: 14px;
          }
        }
      }
    }
  }  
  .social-chat {
    .other-msg {
      margin-left: 15px;
    }
  }
  .social-header {
    h5 {
      span.pull-right {
        display: none;
      }
    }
  }
  // timeline small page css
  .timeline-small {
    .media {
      .timeline-round {
        &.timeline-line-1 {
          &:after {
            bottom: -74px;
            height: 56px;
          }
        }
        &.small-line {
          &:after {
            bottom: -32px;
            height: 14px;
          }
        }
        &.medium-line {
          &:after {
            bottom: -59px;
            height: 40px;
          }
        }
      }
    }
  }
  // landing page css start
  .and-many-more {
    font-size: 30px;
  }
  .landing-main {
    .section-space {
      .landing-header {
        h2 {
          font-size: 18px;
        }
      }
      p {
        font-size: 14px;
      }
    }
  }
  .bg-sky-blue {
    .main-landing {
      .landing-main-content {
        h3 {
          font-size: 22px;
        }
      }
    }
  }
  // landing page css ends
  .custom-card {
    .dashboard-card {
      padding-top: 0;
    }
  }
  .flot-chart-container {
    height: 230px;
  }
  .map-block {
    height: 250px;
  }
  .img-cropper {
    .docs-tooltip {
      font-size: 11px;
    }
    .docs-toggles {
      .btn-group {
        .btn {
          padding: 0.375rem 0.2rem;
        }
      }
    }
  }
  .blog-box {
    .blog-details {
      p {
        line-height: 1.5;
      }
      .blog-social {
        li:last-child {
          padding-left: 0;
        }
      }
    }
    .blog-details-main {
      .blog-social {
        li {
          padding: 0 10px;
        }
      }
    }
  }
  .button-builder-wrap {
    .btn-lg {
      font-size: 18px;
    }
  }

  /* responsive material tabs*/
  .border-tab.nav-tabs {
    .nav-item {
      .nav-link {
        padding: 10px 0px;
      }
    }
  }
  .tabs-responsive-side {
    max-width: 100%;
  }
  //default dashboard
  .browser-widget {
    .media {
      i {
        font-size: 52px;
      }
      .media-body {
        h4 {
          font-size: 20px;
        }
      }
    }
  }
  .bar-chart-widget {
    .bottom-content {
      .num {
        font-size: 20px;
      }
    }
  }
  // from wizard two
  .stepwizard {
    .stepwizard-row {
      .stepwizard-step {
        .btn {
          padding: 6px 20px;
        }
      }
    }
  }
  .user-status {
    table {
      tbody {
        img {
          height: 40px;
        }
      }
    }
  }
  .xs-width-100 {
    min-width: 100%;
  }
  // email app
  .email-wrap {    
    .email-right-aside {
      .email-content {
        .email-wrapper {
          .attachment {
            ul {
              li {
                img {
                  width: 65px;
                  height: 65px;
                }
              }
            }
          }
        }
      }
      .email-top {
        .dropdown-menu {
          left: -58px;
        }
      }
    }
  }

  // form-builder
  .form-builder {
    #components {
      .component {
        .form-group {
          #button1id {
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  // list wish
  .prooduct-details-box {
    .media {
      display: block;
      // padding: 15px;
      .media-body {
        margin-top: 15px;
        margin-left: unset !important;
      }
    }
  }

  // mega-menu
  .mega-menu-p {
    padding: 0 23px !important;
  }

  // according
  .default-according {
    .card {
      .card-header {
        i {
          font-size: 14px;
          top: 24px;
        }
      }
      .btn-link {
        display: block;
        width: 100%;
        padding-left: 17px;
      }
    }
  }

  //rating
  .star-ratings {
    .stars {
      .title {
        line-height: 1;
      }
    }
  }

  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group{
        width:93% !important;
      }
    }
  }

  // form-wizard-four page
  .step-container {
    height: 280px !important;
  }

  // jsgrid-table page
  .sort-panel {
    .d-inline {
      .btn {
        margin-left: 87px;
      }
    }
  }

  // jsgrid-table css
  .jsgrid {
    .jsgrid-pager-container {
      .jsgrid-pager {
        .jsgrid-pager-page {
          padding: 0.5em 0.62em;
        }
      }
    }
  }

  // chat page
  .chat-box {
    .chat-right-aside {
      flex: 0 0 96%;
      max-width: 96%;
      .chat {
        .chat-message {
          width: calc(100% - 20px);
        }
      }
    }
  }
  // error 400 page
  .error-wrapper {
    .error-heading {
      .headline {
        font-size: 100px;
      }
    }
  }
  // gallery page
  .lg-toolbar {
    .lg-icon {
      width: 40px;
    }
  }
  // calender page
  .calendar-wrap {
    a.fc-more {
      font-size: 0.59em;
    }
    .fc {
      th {
        font-size: 10px;
      }
    }
  }
}
@media screen and (min-width:768px) {
  .product-wrapper-grid {
    &.list-view {
      .ribbon{
        display: none;
      }
      .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}

/**=====================
     62. Responsive CSS Ends
==========================**/
